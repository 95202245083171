import React from 'react';
import Carousel from 'react-material-ui-carousel';
import autoBind from 'auto-bind';
import PropertyComponent from '../components/PropertyComponent';

import { getMyProperties, addPropertyToClient } from '../utils/auth';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MyGoogleMap from '../components/MyGoogleMap';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';

import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Paper,
  Button,
  Slider,
  Typography,
} from '@material-ui/core';

// import "../style/SecondExample.scss"

function Project(props) {
  return (
    <Paper
      className="Project"
      style={{
        backgroundColor: props.item.color,
      }}
      elevation={10}
    >
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </Paper>
  );
}

const properties = getMyProperties;
const items = [
  {
    name: 'Lear Music Reader',
    description: 'A PDF Reader specially designed for musicians.',
    color: '#64ACC8',
  },
  {
    name: 'Hash Code 2019',
    description:
      'My Solution on the 2019 Hash Code by Google Slideshow problem.',
    color: '#7D85B1',
  },
  {
    name: 'Terrio',
    description: 'A exciting mobile game game made in the Unity Engine.',
    color: '#CE7E78',
  },
  {
    name: 'React Carousel',
    description: 'A Generic carousel UI component for React using material ui.',
    color: '#C9A27E',
  },
];

export default class PropertyCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoPlay: true,
      animation: 'fade',
      indicators: true,
      timeout: 500,
      navButtonsAlwaysVisible: false,
      selectedProperty: null,
      navButtonsAlwaysInvisible: false,
      clientPopupVisible: false,
      checked: [],
      showError: false,
      showSuccess: false,
    };

    autoBind(this);
    this.addToClient = function (property) {
      window.scrollTo(0, 0);
      this.setState({ selectedProperty: property });
      this.setState({ clientPopupVisible: true });
    };
    this.handleToggle = (value) => () => {
      const currentIndex = this.state.checked.indexOf(value);
      const newChecked = [...this.state.checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      this.setState({ checked: newChecked });
    };
    this.closeClientChooser = function () {
      this.setState({ selectedProperty: null });
      this.setState({ clientPopupVisible: false });
      this.setState({ checked: [] });
    };
    this.saveToClient = function () {
      if (this.state.checked.length) {
        if (
          addPropertyToClient(this.state.selectedProperty, this.state.checked)
        ) {
          this.setState({ showSuccess: true });
          let _this = this;
          setTimeout(function () {
            _this.setState({ selectedProperty: null });
            _this.setState({ clientPopupVisible: false });
            _this.setState({ checked: [] });
            _this.setState({ showSuccess: false });
          }, 2000);
        }
      } else {
        this.setState({ showError: true });
      }
    };
  }

  render() {
    const { clients } = this.props;
    return (
      <div
        style={{ marginTop: '100px', color: '#494949' }}
        className="marginBottomLarge paddingTopMedium"
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          spacing={0}
        >
          <Grid
            item
            xs={11}
            sm={10}
            md={6}
            lg={5}
            className="textCenter paddingBottomSmall"
          >
            {clients && this.state.clientPopupVisible && (
              <div className="clientChooserContainer">
                <div className="clientChooser">
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                    spacing={0}
                    data-aos="fade"
                    data-aos-duration="1000"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      className="textCenter"
                    >
                      {this.state.showError && (
                        <Alert severity="error">
                          Please select at least one client!
                        </Alert>
                      )}
                      {this.state.showSuccess && (
                        <Alert severity="success">Successfully added.</Alert>
                      )}

                      <Typography
                        variant="h5"
                        gutterBottom
                        data-aos="fade-up"
                        align="center"
                      >
                        Clients
                      </Typography>
                      <List dense className="clientList">
                        {clients.map((client, index) => {
                          return (
                            <ListItem
                              key={client.id}
                              button
                              className="clientListItem"
                            >
                              <ListItemAvatar>
                                <Avatar
                                  alt={`Avatar n°${client.id + 1}`}
                                  src={client.profilePic}
                                  className="clientAvatar"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={client.email}
                                primary={client.name}
                                secondary={
                                  client.email + '\n' + client.location
                                }
                                secondaryTypographyProps={{
                                  className: 'secondaryClientText',
                                }}
                              />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="end"
                                  onChange={this.handleToggle(client.id)}
                                  checked={
                                    this.state.checked.indexOf(client.id) !== -1
                                  }
                                  inputProps={{
                                    'aria-labelledby': client.email,
                                  }}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="paddingBottomSmall myPropertyButtons"
                      align="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.closeClientChooser();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="paddingBottomSmall myPropertyButtons"
                      align="center"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.saveToClient();
                        }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            <Carousel
              className="SecondExample marginBottomSmMd"
              autoPlay={false}
              fullHeightHover={false}
              NextIcon={<ChevronRightIcon className="myPropertiesRight" />}
              PrevIcon={<ChevronLeftIcon className="myPropertiesLeft" />}
              navButtonsProps={{
                style: {
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                },
              }}
              navButtonsWrapperProps={{
                style: {
                  bottom: 'unset',
                  top: '10%',
                },
              }}
              animation="slide"
              indicators={false}
              timeout={this.state.timeout}
              navButtonsAlwaysVisible
            >
              {this.props.properties.map((property, index) => {
                return (
                  <>
                    <PropertyComponent
                      link
                      id={property.id}
                      key={property.id}
                      image={property.data.featuredImage}
                      address={
                        property.data.streetAddress +
                        ', ' +
                        property.data.city +
                        ', ' +
                        property.data.state
                      }
                      street={property.data.streetAddress}
                      location={property.data.city + ', ' + property.data.state}
                      price={property.data.price}
                      gain={property.data.maxGain}
                      livingAreaSquareFootage={
                        property.data.livingAreaSquareFootage
                      }
                      nonLiveableAreaSquareFootage={
                        property.data.garageSF + property.data.deckSF
                      }
                      maxLotCoverage={property.data.maxLotCoverage}
                      className="paddingBottomSmall"
                    />
                    {/* <div className="main-wrapper paddingBottomSmall">
                      <MyGoogleMap
                        address={
                          property.data.streetAddress +
                          ', ' +
                          property.data.city +
                          ', ' +
                          property.data.state
                        }
                      />
                    </div> */}
                    {clients && (
                      <Grid
                        item
                        xs={12}
                        className="paddingBottomSmall myPropertyButtons"
                        align="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.addToClient(property);
                          }}
                        >
                          Assign to Client
                        </Button>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      className="paddingBottomSmall myPropertyButtons"
                      align="center"
                    >
                      <Link
                        to={'/property/' + property.id}
                        className="noUnderline"
                      >
                        <Button fullWidth variant="contained" color="primary">
                          View Details
                        </Button>
                      </Link>
                    </Grid>
                  </>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      </div>
    );
  }
}
