import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useLocation, useHistory, Link } from 'react-router-dom';

import BottomNavigationMenu from '../components/BottomNavigationMenu';
import { PropertyData } from '../utils/defaultData';
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import PropertyComponent from '../components/PropertyComponent';
import TopMenu from '../components/TopMenu';

function Search(props) {
  const [properties, setProperties] = useState([]);
  const [selectedRangeLow, setSelectedRangeLow] = useState(500);
  const [selectedRangeHigh, setSelectedRangeHigh] = useState(4000);
  const [selectedPriceRangeLow, setSelectedPriceRangeLow] = useState(500000);
  const [selectedPriceRangeHigh, setSelectedPriceRangeHigh] = useState(3000000);
  const [locationSelection, setLocationSelection] = useState('All');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [showProperties, setShowProperties] = useState(false);
  const [noResults, setNoResults] = useState(false);

  let history = useHistory();

  const handleChangeLow = (event) => {
    setNoResults(false);
    setSelectedRangeLow(event.target.value);
  };

  const handleChangeHigh = (event) => {
    setNoResults(false);
    setSelectedRangeHigh(event.target.value);
  };

  const handlePriceChangeLow = (event) => {
    setNoResults(false);
    setSelectedPriceRangeLow(event.target.value);
  };

  const handlePriceChangeHigh = (event) => {
    setNoResults(false);
    setSelectedPriceRangeHigh(event.target.value);
  };
  const handleChange = (event) => {
    setNoResults(false);
    setLocationSelection(event.target.value);
  };
  const filterProperties = () => {
    console.log(PropertyData, selectedRangeLow, selectedRangeHigh);
    let filteredProperties = [];
    for (var propertyId in PropertyData) {
      if (
        PropertyData[propertyId].maxLotCoverage >= selectedRangeLow &&
        PropertyData[propertyId].maxLotCoverage <= selectedRangeHigh &&
        PropertyData[propertyId].price >= selectedPriceRangeLow &&
        PropertyData[propertyId].price <= selectedPriceRangeHigh
      ) {
        console.log(city, county);
        if (city !== '' || county !== '') {
          if (PropertyData[propertyId].city === city) {
            let propertyObj = {};
            propertyObj.id = propertyId;
            propertyObj.data = PropertyData[propertyId];
            filteredProperties.push(propertyObj);
          }
        } else {
          let propertyObj = {};
          propertyObj.id = propertyId;
          propertyObj.data = PropertyData[propertyId];
          filteredProperties.push(propertyObj);
        }
      }
    }
    if (filteredProperties.length > 0) {
      setNoResults(false);
      setProperties(filteredProperties);
      setShowProperties(true);
    } else {
      setNoResults(true);
    }
  };
  const clearFilters = () => {
    if (showProperties) {
      setProperties([]);
      setShowProperties(false);
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <TopMenu {...props} onBackClick={clearFilters} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="marginBottomLarge paddingTopXLarge"
        data-aos="fade"
        data-aos-duration="1000"
      >
        <Grid item xs={11}>
          <Typography variant="h5" gutterBottom align="center" gutterBottom>
            Search
          </Typography>
          {!showProperties && (
            <div className="paddingTopMedium">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
                className="paddingBottomSmall"
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={6}
                    className="textCenter paddingBottomSmall"
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="left"
                      gutterBottom
                    >
                      Location
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className="paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <TextField
                      id="county"
                      label="City"
                      name="city"
                      value={city}
                      className="textBlack"
                      onChange={(e) => {
                        setNoResults(false);
                        setCity(e.target.value);
                      }}
                    />
                    {/* <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={locationSelection}
                      onChange={handleChange}
                      label="Data Selections"
                    >
                      <MenuItem value={'Larkspur'}>Larkspur</MenuItem>
                      <MenuItem value={'Corte Madera'}>Corte Madera</MenuItem>
                      <MenuItem value={'All'}>All Locations</MenuItem>
                    </Select> */}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  className="paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <TextField
                      id="county"
                      label="County"
                      name="county"
                      value={county}
                      onChange={(e) => {
                        setNoResults(false);
                        setCounty(e.target.value);
                      }}
                      className="textBlack"
                    />
                    {/* <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={locationSelection}
                      onChange={handleChange}
                      label="Data Selections"
                    >
                      <MenuItem value={'Larkspur'}>Larkspur</MenuItem>
                      <MenuItem value={'Corte Madera'}>Corte Madera</MenuItem>
                      <MenuItem value={'All'}>All Locations</MenuItem>
                    </Select> */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
                className="textCenter paddingBottomSmall"
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={6}
                    className="textCenter paddingBottomSmall"
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="left"
                      gutterBottom
                    >
                      Price
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className="textCenter paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPriceRangeLow}
                      onChange={handlePriceChangeLow}
                    >
                      <MenuItem value={500000}>$500,000</MenuItem>
                      <MenuItem value={600000}>$600,000</MenuItem>
                      <MenuItem value={700000}>$700,000</MenuItem>
                      <MenuItem value={800000}>$800,000</MenuItem>
                      <MenuItem value={900000}>$900,000</MenuItem>
                      <MenuItem value={1000000}>$1,000,000</MenuItem>
                      <MenuItem value={1200000}>$1,200,000</MenuItem>
                      <MenuItem value={1400000}>$1,400,000</MenuItem>
                      <MenuItem value={1600000}>$1,600,000</MenuItem>
                      <MenuItem value={1800000}>$1,800,000</MenuItem>
                      <MenuItem value={2000000}>$2,000,000</MenuItem>
                      <MenuItem value={2500000}>$2,500,000</MenuItem>
                      <MenuItem value={3000000}>$3,000,000</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className="textCenter paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPriceRangeHigh}
                      onChange={handlePriceChangeHigh}
                    >
                      <MenuItem value={500000}>$500,000</MenuItem>
                      <MenuItem value={600000}>$600,000</MenuItem>
                      <MenuItem value={700000}>$700,000</MenuItem>
                      <MenuItem value={800000}>$800,000</MenuItem>
                      <MenuItem value={900000}>$900,000</MenuItem>
                      <MenuItem value={1000000}>$1,000,000</MenuItem>
                      <MenuItem value={1200000}>$1,200,000</MenuItem>
                      <MenuItem value={1400000}>$1,400,000</MenuItem>
                      <MenuItem value={1600000}>$1,600,000</MenuItem>
                      <MenuItem value={1800000}>$1,800,000</MenuItem>
                      <MenuItem value={2000000}>$2,000,000</MenuItem>
                      <MenuItem value={2500000}>$2,500,000</MenuItem>
                      <MenuItem value={3000000}>$3,000,000</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={6}
                  className="textCenter paddingBottomSmall"
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    align="left"
                    gutterBottom
                  >
                    Potential Square Footage
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className="textCenter paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRangeLow}
                      onChange={handleChangeLow}
                    >
                      <MenuItem value={500}>500 Sq. Ft.</MenuItem>
                      <MenuItem value={1000}>1000 Sq. Ft.</MenuItem>
                      <MenuItem value={1500}>1500 Sq. Ft.</MenuItem>
                      <MenuItem value={2000}>2000 Sq. Ft.</MenuItem>
                      <MenuItem value={2500}>2500 Sq. Ft.</MenuItem>
                      <MenuItem value={3000}>3000 Sq. Ft.</MenuItem>
                      <MenuItem value={3500}>3500 Sq. Ft.</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className="textCenter paddingBottomSmall"
                >
                  <FormControl className="fullWidth">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRangeHigh}
                      onChange={handleChangeHigh}
                    >
                      <MenuItem value={2000}>2000 Sq. Ft.</MenuItem>
                      <MenuItem value={2500}>2500 Sq. Ft.</MenuItem>
                      <MenuItem value={3000}>3000 Sq. Ft.</MenuItem>
                      <MenuItem value={3500}>3500 Sq. Ft.</MenuItem>
                      <MenuItem value={4000}>4000 Sq. Ft.</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4}
                    className="paddingBottomSmall myPropertyButtons"
                    align="center"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        filterProperties();
                      }}
                    >
                      View Properties
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          {noResults && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                className="textCenter paddingBottomSmall"
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  gutterBottom
                >
                  No Results
                </Typography>
              </Grid>
            </Grid>
          )}
          {showProperties && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              {properties.map((property) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  className="textCenter paddingBottomSmall"
                  key={property.id}
                >
                  <PropertyComponent
                    link
                    id={property.id}
                    image={property.data.featuredImage}
                    address={
                      property.data.streetAddress +
                      ', ' +
                      property.data.city +
                      ', ' +
                      property.data.state
                    }
                    street={property.data.streetAddress}
                    location={property.data.city + ', ' + property.data.state}
                    price={property.data.price}
                    gain={property.data.maxGain}
                    livingAreaSquareFootage={
                      property.data.livingAreaSquareFootage
                    }
                    nonLiveableAreaSquareFootage={
                      property.data.garageSF + property.data.deckSF
                    }
                    maxLotCoverage={property.data.maxLotCoverage}
                    className="paddingBottomSmall"
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <BottomNavigationMenu />
    </>
  );
}

export default Search;
