import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import BottomNavigationMenu from '../components/BottomNavigationMenu';
import TopMenu from '../components/TopMenu';

function MyClients(props) {
  const { isAuthorized, userData } = props;
  console.log('Is authorized: ', isAuthorized);
  console.log('user data clients: ', userData);
  let history = useHistory();
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    if (!isAuthorized) {
      history.push('/');
    }
  }, []);
  return (
    <>
      <TopMenu {...props} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-end"
        spacing={0}
        data-aos="fade"
        data-aos-duration="1000"
        className="paddingTopXLarge"
      >
        <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
          <Typography variant="h5" gutterBottom align="center">
            Clients
          </Typography>
        </Grid>
      </Grid>
      {userData.clients && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          spacing={0}
          data-aos="fade"
          data-aos-duration="1000"
        >
          <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
            <List dense className="clientList">
              {userData.clients.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`;
                return (
                  <ListItem
                    key={value.id}
                    button
                    className="clientListItem"
                    onClick={() => {
                      history.push(`/client/${value.id}`);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={`Avatar n°${value.id + 1}`}
                        src={value.profilePic}
                        className="clientAvatar"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={value.name}
                      secondary={value.email + '\n' + value.location}
                      secondaryTypographyProps={{
                        className: 'secondaryClientText',
                      }}
                    />
                    <ListItemSecondaryAction></ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      )}

      <BottomNavigationMenu />
    </>
  );
}

export default MyClients;
