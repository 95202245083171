import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA);

export function useGAEvent() {
  const [event, setEvent] = useState({});
  useEffect(() => {
    const { category, action } = event;
    if (category && action) {
      ReactGA.event(event);
    }
  }, [event]);

  return { setEvent };
}

export default function PageTracker({ options }) {
  const location = useLocation();
  useEffect(() => {
    const trackPage = (page) => {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.pageview(page);
    };
    trackPage(location.pathname);
  }, [location.pathname, options]);

  return null;
}
