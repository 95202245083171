// MyGoogleMaps.js
import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import AutoComplete from './Autocomplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [-34.397, 150.644],
    zoom: 18,
    address: '',
    draggable: true,
    lat: null,
    lng: null,
  };
  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };
  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
    this.setMap();
  };
  setMap() {
    const { mapApi } = this.state;
    let _this = this;
    const geocoder = new mapApi.Geocoder();
    geocoder.geocode(
      { address: _this.props.address },
      function (results, status) {
        if (status == 'OK') {
          _this.setState({
            center: [
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
            ],
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        } else {
          alert(
            'Geocode was not successful for the following reason: ' + status
          );
        }
      }
    );
  }

  render() {
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <Wrapper>
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: 'AIzaSyBjtj-Z_D69gASyj5Z8ipWfBaFxTPEhPbs',
            libraries: ['places', 'geometry'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            text={this.state.address}
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>
      </Wrapper>
    );
  }
}

export default MyGoogleMap;
