import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BottomNavigationMenu from '../components/BottomNavigationMenu';
import TopMenu from '../components/TopMenu';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { logout } from '../utils/auth';

function Settings(props) {
  const { isAuthorized, userData } = props;
  console.log('Is authorized: ', isAuthorized);
  console.log('user data clients: ', userData);
  let history = useHistory();
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    if (!isAuthorized) {
      history.push('/');
    }
  }, []);

  return (
    <>
      <TopMenu {...props} />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={11} md={6} large={4}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={0}
            data-aos="fade"
            data-aos-duration="1000"
            className="paddingTopXLarge"
          >
            <Grid item xs={12} className=" paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<CreateIcon />}
              >
                Me
              </Button>
            </Grid>
            <Grid item xs={12} className=" paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<NotificationsIcon />}
              >
                Notifications
              </Button>
            </Grid>
            <Grid item xs={12} className=" paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<SettingsIcon />}
              >
                General
              </Button>
            </Grid>
            <Grid item xs={12} className=" paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<PersonIcon />}
              >
                Account
              </Button>
            </Grid>
            <Grid item xs={12} className="paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<LockIcon />}
              >
                Privacy
              </Button>
            </Grid>
            <Grid item xs={12} className="paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<HelpIcon />}
              >
                Help
              </Button>
            </Grid>
            <Grid item xs={12} className="paddingBottomSmall homeUserButtons">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  logout();
                  history.push('/');
                }}
                startIcon={<ExitToAppIcon />}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BottomNavigationMenu />
    </>
  );
}

export default Settings;
