import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import BottomNavigationMenu from '../components/BottomNavigationMenu';
import TopMenu from '../components/TopMenu';

import PropertyCarousel from '../components/PropertyCarousel';
import { isLoggedIn } from '../utils/auth';

function MyProperties(props) {
  const { isAuthorized } = props;
  console.log('Is authorized: ', isAuthorized);
  let history = useHistory();
  const [checked, setChecked] = React.useState([1]);
  const [userData, setUserData] = useState(isLoggedIn());
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    if (!isAuthorized) {
      history.push('/');
    }
  }, []);
  return (
    <>
      {userData && (
        <>
          <TopMenu {...props} />
          {userData.savedProperties && (
            <PropertyCarousel
              properties={userData.savedProperties}
              clients={userData.clients}
            />
          )}

          <BottomNavigationMenu />
        </>
      )}
    </>
  );
}

export default MyProperties;
