import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    color: {
      main: '#000000',
      primary: '#000000',
      secondary: '#FFFFFF',
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
      disabled: '#6EAFB3',
    },
    button: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
      paper: '#000000',
    },
  },
  typography: {
    useNextVariants: true,
    color: {
      secondary: '#FFFFFF',
    },
    fontFamily: ['compassmedium', 'sans-serif'].join(','),
    h5: {
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontKerning: 'none',
    },
    body2: {
      lineHeight: 1.8,
    },
  },
  // overrides: {
  //     MuiButton: {
  //         text: {
  //             color: '#white',
  //             fontWeight: 800,
  //         },
  //         contained: {
  //             fontWeight: '800 !important',
  //             backgroundColor: 'white',
  //             color: '#6EAFB3 !important',
  //             minHeight: '3.5rem !important',
  //             '&:hover': {
  //                 backgroundColor: '#6EAFB3 !important',
  //                 color: '#FFF !important',
  //                 border: '1px solid white !important'
  //             }
  //         }
  //     },
  //     MuiTextField: {
  //         root: {
  //             background: 'rgba(255,255,255,.5)'
  //         }
  //     },
  //     MuiOutlinedInput: {
  //         notchedOutline: {
  //             borderColor: '#6EAFB3 !important',
  //             borderWidth: '3px'
  //         },
  //         adornedEnd: {
  //             color: 'black'
  //         }
  //     },
  //     MuiFilledInput: {
  //         underline: {
  //             color: 'white',
  //             borderBottomColor: 'white'
  //         },

  //         "root": {
  //             "&$focused": {
  //               "borderColor": "white"
  //             }
  //         }
  //     }
  // },
});

export default responsiveFontSizes(theme);
