import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BottomNavigationMenu from "../components/BottomNavigationMenu";
import TopMenu from "../components/TopMenu";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import CreateIcon from "@material-ui/icons/Create";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/Help";
import { Link } from "react-router-dom";
import { isLoggedIn, getClientInfo } from "../utils/auth";
import { List, ListItem, ListItemText } from "@material-ui/core";

function Client(props) {
  let history = useHistory();
  const { pathname } = useLocation();

  const [userData, setUserData] = useState(isLoggedIn());
  const [clientData, setClientData] = useState(
    getClientInfo(/[^/]*$/.exec(pathname)[0])
  );

  useEffect(() => {
    if (!userData) {
      history.push("/");
    }
  }, []);
  return (
    <>
      <TopMenu {...props} back="/clients" />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={11}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={0}
            data-aos="fade"
            data-aos-duration="1000"
            className="paddingTopXLarge"
          >
                      <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
          <Typography
            variant="h4"
            gutterBottom
            data-aos="fade-up"
            align="center"
          >
            {clientData.name}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            data-aos="fade-up"
            align="center"
          >
            {clientData.location}
          </Typography>
        </Grid>
              
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <List dense className="">
                <ListItem
                  key={clientData.id}
                  button
                  className=""
                  component="a"
                  href={`tel:${clientData.phone}`}
                >
                  <ListItemText
                    id={clientData.phone}
                    primary={clientData.phone}
                    secondary={'Mobile'}
                    secondaryTypographyProps={{
                      className: "secondaryClientText",
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <List dense className="">
                <ListItem
                  key={clientData.id}
                  button
                  className=""
                  component="a"
                  href={`mailto:${clientData.email}`}
                >
                  <ListItemText
                    id={clientData.email}
                    primary={clientData.email}
                    secondary={'Email'}
                    secondaryTypographyProps={{
                      className: "secondaryClientText",
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <List dense className="">
                <ListItem
                  key={clientData.id}
                  button
                  className=""
                  onClick={()=>{history.push(`/properties`)}}
                >
                  <ListItemText
                    id={clientData.email}
                    primary={'Assign New Property'}

                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <List dense className="">
                <ListItem
                  key={clientData.id}
                  button
                  className=""
                  onClick={()=>{console.log("View Preferences")}}
                >
                  <ListItemText
                    id={clientData.email}
                    primary={'View Preferences'}

                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <List dense className="">
                <ListItem
                  key={clientData.id}
                  button
                  className=""
                  onClick={()=>{history.push(`/client-properties/${clientData.id}`)}}
                >
                  <ListItemText
                    id={clientData.email}
                    primary={'View Saved'}

                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BottomNavigationMenu />
    </>
  );
}

export default Client;
