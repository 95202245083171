import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { useLocation, useHistory } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';

const NavigationLibrary = {
  '/': 0,
  '/search': 1,
  '/clients': 2,
  '/settings': 3,
};

function BottomNavigationMenu(props) {
  const { pathname } = useLocation();
  const { isAuthorized, hide } = props;
  let history = useHistory();
  return (
    <>
      {isAuthorized && !hide && (
        <AppBar
          position="fixed"
          color="primary"
          style={{ top: 'auto', bottom: 0 }}
        >
          <BottomNavigation
            value={NavigationLibrary[pathname]}
            onChange={(event, newValue) => {
              history.push(event.currentTarget.getAttribute('link'));
            }}
          >
            <BottomNavigationAction link="/" label="Home" icon={<HomeIcon />} />
            <BottomNavigationAction
              link="/search"
              label="Search"
              icon={<SearchIcon />}
            />
            <BottomNavigationAction
              link="/clients"
              label="Clients"
              icon={<SupervisorAccountIcon />}
            />
            <BottomNavigationAction
              link="/settings"
              label="Settings"
              icon={<SettingsIcon />}
            />
          </BottomNavigation>
        </AppBar>
      )}
    </>
  );
}
export default BottomNavigationMenu;
