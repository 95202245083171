import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

function createData(title, data) {
  return { title, data};
}


export default function PropertyCharacteristics(props) {
  const classes = useStyles();
  const {data} = props;


  let rows = [];

  for(var i = 0; i<data.length; i++){
      rows.push(createData(data[i].title, data[i].data));
  }

  return (
    <TableContainer component={Paper} className="marginBottomLarge">
      <Table className={classes.table} size="small" aria-label="simple table">

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title}>
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{row.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}