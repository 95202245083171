import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeIcon from '@material-ui/icons/Home';

import TopMenu from '../components/TopMenu';

import ImgSearchIcon from '../static/images/home/search-icon.png';
import ImgMapIcon from '../static/images/home/map-icon.png';
import ImgCostIcon from '../static/images/home/cost-icon.png';
import ImgNotificationIcon from '../static/images/home/notification-icon.png';
import ImgInformationIcon from '../static/images/home/information-icon.png';
import ImgNetworkIcon from '../static/images/home/network-icon.png';
import Video from '../static/videos/temp-vid.mp4';
import VideoCover from '../static/images/home/video-cover.png';
// import FeaturePropChar from '../static/images/home/feature-property-char.gif';
// import FeatureConstructionCalc from '../static/images/home/feature-construction-calc.gif';
// import FeaturePlanningDevStandards from '../static/images/home/feature-planning-dev-standards.gif';
// import FeaturePropPot from '../static/images/home/feature-property-potential.png';
// import FeatureZoning from '../static/images/home/feature-zoning.gif';

import { Fragment } from 'react';
import PropertyComponent from '../components/PropertyComponent';
import { FeaturedProperties } from '../utils/defaultData';
import { Link } from 'react-router-dom';
import HeroImageComponent from '../components/HeroImage';

function Home(props) {
  const { isAuthorized, userData, refreshProps } = props;

  function scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

  const [keyFeatures, setKeyFeatures] = useState([
    {
      id: 1,
      name: 'Property Characteristics',
      asset:
        'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-property-char.gif',
    },
    {
      id: 2,
      name: 'Property Potential',
      asset:
        'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-property-potential.png',
    },
    {
      id: 3,
      name: 'Local Planning Development Standards',
      asset:
        'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-planning-dev-standards.gif',
    },
    {
      id: 4,
      name: 'Zoning Codes/Ordinances',
      asset: 'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-zoning.gif',
    },
    {
      id: 5,
      name: 'Estimated Local Construction Costs',
      asset:
        'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-estimated-construction.png',
    },
    {
      id: 6,
      name: 'Construction Cost Calculator',
      asset:
        'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-construction-calc.gif',
    },
  ]);
  const [activeFeature, setActiveFeature] = useState({
    id: 1,
    name: 'Property Characteristics',
    asset:
      'https://qdev-eth.s3.us-west-2.amazonaws.com/feature-property-char.gif',
  });

  useEffect(() => {
    console.log('Refreshing props');
    refreshProps();
  }, []);
  function AuthorizedContent() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="paddingTopMedium"
      >
        <Grid item xs={11}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={0}
            data-aos="fade"
            data-aos-duration="1000"
            className="paddingTopLarge"
          >
            <Grid item xs={6} md={4} lg={2} xl={2} className="">
              <img className="fullWidth" src={userData.profilePic} />
            </Grid>
            <Grid item xs={6} className="homeUserData paddingLeftSmall">
              <Typography variant="h6">Welcome,</Typography>
              <div className="paddingLeftSmall">
                <Typography variant="body1">{userData.name}</Typography>
                <Typography variant="body2" className="customBodySmall">
                  {userData.city}, {userData.state}
                </Typography>
                <Typography variant="body2" className="customBodySmall">
                  DRE #{userData.dreNumber}
                </Typography>
              </div>
            </Grid>
            <hr className="homeAuthHr" />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={0}
              data-aos="fade"
              data-aos-duration="1000"
            >
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                xl={2}
                className="paddingTopMedium paddingBottomSmall homeUserButtons"
              >
                <Link to="/properties" className="noUnderline">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<HomeIcon />}
                  >
                    My Properties
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={0}
              data-aos="fade"
              data-aos-duration="1000"
            >
              <Grid
                item
                xs={12}
                xs={12}
                md={4}
                lg={3}
                xl={2}
                className="paddingBottomSmall homeUserButtons"
              >
                <Link to="/clients" className="noUnderline">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<SupervisorAccountIcon />}
                  >
                    My Clients
                  </Button>
                </Link>
              </Grid>
            </Grid>{' '}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={0}
              data-aos="fade"
              data-aos-duration="1000"
            >
              <Grid
                item
                xs={12}
                xs={12}
                md={4}
                lg={3}
                xl={2}
                className="paddingBottomSmall homeUserButtons"
              >
                <Link to="/search" className="noUnderline">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                  >
                    New Search
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  // function UnAuthorizedContent() {
  //   return (
  //     <Grid
  //       container
  //       direction="row"
  //       justify="center"
  //       alignItems="center"
  //       spacing={2}
  //       className="paddingTopLarge"
  //     >
  //       <Grid item xs={12} md={4} className="paddingBottomSmall">
  //         <ReactPlayer
  //           url={Video}
  //           width="100%"
  //           light={VideoCover}
  //           playing
  //           controls
  //         />
  //       </Grid>
  //       <Grid item xs={12} md={6}>
  //         <Typography variant="h4">What is Ether?</Typography>
  //         <Hidden smDown>
  //           <Typography variant="body2">
  //             Quis laborum ea proident quis laboris in non sit. Officia
  //             voluptate Lorem dolor Lorem fugiat et duis cupidatat et duis
  //             laborum sunt. Labore et quis irure sint esse minim labore irure
  //             qui. Ex adipisicing duis commodo aliqua ad.
  //           </Typography>
  //         </Hidden>
  //       </Grid>
  //       <Hidden mdUp>
  //         <Grid item xs={12} md={6} className="paddingBottomSmall">
  //           <Typography variant="body2">
  //             Quis laborum ea proident quis laboris in non sit. Officia
  //             voluptate Lorem dolor Lorem fugiat et duis cupidatat et duis
  //             laborum sunt. Labore et quis irure sint esse minim labore irure
  //             qui. Ex adipisicing duis commodo aliqua ad.
  //           </Typography>
  //         </Grid>
  //       </Hidden>
  //       <Grid item xs={12} className="textCenter paddingBottomSmall">
  //         <Typography variant="h4" gutterBottom>
  //           Unlock the Potential
  //         </Typography>
  //       </Grid>
  //       {FeaturedProperties.map((property) => (
  //         <Grid
  //           item
  //           xs={12}
  //           sm={12}
  //           md={12}
  //           lg={3}
  //           className="textCenter paddingBottomSmall"
  //           key={property.id}
  //         >
  //           <PropertyComponent
  //             type="featured"
  //             link
  //             id={property.id}
  //             image={property.data.featuredImage}
  //             address={
  //               property.data.streetAddress +
  //               ', ' +
  //               property.data.city +
  //               ', ' +
  //               property.data.state
  //             }
  //             street={property.data.streetAddress}
  //             location={property.data.city + ', ' + property.data.state}
  //             price={property.data.price}
  //             gain={property.data.maxGain}
  //             livingAreaSquareFootage={property.data.livingAreaSquareFootage}
  //             nonLiveableAreaSquareFootage={
  //               property.data.garageSF + property.data.deckSF
  //             }
  //             maxLotCoverage={property.data.maxLotCoverage}
  //             className="paddingBottomSmall"
  //           />
  //         </Grid>
  //       ))}
  //     </Grid>
  //   );
  // }

  function UnAuthorizedContentNew() {
    return (
      <>
        <HeroImageComponent />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={10}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className="paddingTopLarge paddingBottomLarge"
            >
              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgSearchIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="body2" align="center">
                  Browse real estate opportunities
                  <br /> that have potential to develop <br />
                  additional square footage.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgMapIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="body2" align="center">
                  Explore local planning <br />
                  standards and codes at <br />
                  your fingertips.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgCostIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="body2" align="center">
                  Easily review and compare <br />
                  estimated costs and <br />
                  return on investments.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="missionSection"
            >
              <Grid
                item
                xs={11}
                md={4}
                className="paddingBottomSmall textCenter textWhite"
              >
                <Typography variant="h4" align="left" gutterBottom>
                  Our Mission
                </Typography>
                <Typography variant="body1" align="left">
                  To provide accessibility to information for investors to make
                  better real estate purchasing decisions.
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={4}
                className="paddingBottomSmall textCenter textWhite"
              >
                <Typography variant="body1" align="left">
                  When purchasing real estate, an investor is not only investing
                  in the land and/or building, but more importantly the
                  entitlements for expansion.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
              className=""
            >
              <div className="player-wrapper">
                <video controls poster={VideoCover}>
                  <source
                    src="https://qdev-eth.s3.us-west-2.amazonaws.com/ETHER+ESTIMATES+SPLASH+VID+04+rev02-0.mp4"
                    type="video/mp4"
                  />
                </video>
                {/* <ReactPlayer
                  className="react-player"
                  url={Video}
                  light={VideoCover}
                  playing
                  controls
                /> */}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="paddingTopLarge paddingBottomLarge"
            >
              <Grid item xs={11} md={9} className="textCenter">
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  className="paddingBottomSmall"
                >
                  Understand the Attributes of Your Real Estate Investment
                </Typography>
                <Typography variant="body1" align="center">
                  ETHER provides a consolidated view of a property’s potential
                  expansion by making construction costs, planning codes and
                  property restrictions readily accessible.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          id="keyFeats"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="keyFeaturesSection"
            >
              <Hidden mdUp>
                <Grid
                  item
                  xs={11}
                  md={4}
                  className="paddingBottomSmall textCenter textWhite minHeightTen"
                >
                  {keyFeatures.map((feature) => (
                    <img
                      src={feature.asset}
                      className={
                        activeFeature.id === feature.id
                          ? 'featureAsset'
                          : 'featureAsset hidden'
                      }
                    />
                  ))}
                </Grid>
              </Hidden>
              <Grid
                item
                xs={11}
                md={4}
                className="paddingBottomSmall textCenter textWhite"
              >
                {keyFeatures.map((feature) => (
                  <Typography
                    variant="body1"
                    align="left"
                    onMouseOver={(e) => {
                      var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
                      root.setAttribute('class', 'ovHidden');

                      // e.preventDefault();
                      let current = window.pageYOffset;
                      setActiveFeature(feature);
                      // alert(window.pageYOffset);
                      // const yOffset = -50;
                      // const element = document.getElementById('keyFeats');
                      // var props = element.getBoundingClientRect();
                      // var obj = {};
                      // for (var prop in props) {
                      //   obj[prop] = props[prop];
                      // }
                      // alert(props.top, window.pageYOffset, yOffset);
                      // const y = props.top + window.pageYOffset + yOffset;
                      // alert('scroll to: ', y);
                      // alert(props.top);
                      // document.getElementById('keyFeats').scrollIntoView(true);
                      // let xl = props.top;
                      // setTimeout(
                      window.scrollTo({
                        top: current,
                      });
                      setTimeout(function () {
                        root.removeAttribute('class');
                      }, 200);

                      // scrollTo(current, function () {
                      //   root.removeAttribute('class');
                      // });
                      // root.removeAttribute('class');
                      //   200
                      // );
                    }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setActiveFeature(feature);
                    //   // document.getElementById('keyFeats').scrollIntoView();
                    //   // if (
                    //   //   /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                    //   //     navigator.userAgent
                    //   //   ) ||
                    //   //   /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    //   //     navigator.userAgent.substr(0, 4)
                    //   //   )
                    //   // ) {
                    //   alert('hi');
                    //   const yOffset = -80;
                    //   const element = document.getElementById('keyFeats');
                    //   const y =
                    //     element.getBoundingClientRect().top +
                    //     window.pageYOffset +
                    //     yOffset;

                    //   setTimeout(
                    //     window.scrollTo({ top: y, behavior: 'smooth' }),
                    //     100
                    //   );
                    //   // }
                    // }}
                    className={
                      activeFeature.id === feature.id
                        ? 'featureActive'
                        : 'feature'
                    }
                  >
                    {feature.name}
                  </Typography>
                ))}
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  xs={11}
                  md={4}
                  className="paddingBottomSmall textCenter textWhite minHeightTen"
                >
                  {/* {keyFeatures.map((feature) => (
                    <img
                      src={feature.asset}
                      className={
                        activeFeature.id === feature.id
                          ? 'featureAsset'
                          : 'featureAsset hidden'
                      }
                    />
                  ))} */}
                  <img src={activeFeature.asset} className={'featureAsset'} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={10}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="paddingTopLarge paddingBottomSmall"
            >
              <Grid
                item
                xs={11}
                md={9}
                className="paddingBottomSmall textCenter"
              >
                <Typography variant="h4" align="center" gutterBottom>
                  Collaboration Made Simple
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className="paddingBottomLarge"
            >
              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgNotificationIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="h6" align="center">
                  Notifications
                </Typography>
                <Typography variant="body2" align="center">
                  Receive immediate notification <br />
                  of newly listed properties that <br />
                  meet your search criteria.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgInformationIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="h6" align="center">
                  Quick Information Sharing
                </Typography>
                <Typography variant="body2" align="center">
                  Create reports with links <br />
                  to properties to send <br />
                  to potential clients.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="paddingBottomSmall textCenter"
              >
                <img
                  src={ImgNetworkIcon}
                  className="paddingBottomSmall textIconHeader"
                />
                <Typography variant="h6" align="center">
                  Network
                </Typography>
                <Typography variant="body2" align="center">
                  Search the ETHER community <br />
                  for potential off market buyers.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="footerSection"
            >
              <Grid
                item
                xs={11}
                md={3}
                className="paddingBottomSmall textCenter "
              >
                <Typography variant="body2" align="center">
                  Sign Up
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={3}
                className="paddingBottomSmall textCenter "
              >
                <Typography variant="body2" align="center">
                  Pricing
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={3}
                className="paddingBottomSmall textCenter "
              >
                <Typography variant="body2" align="center">
                  Team
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={3}
                className="paddingBottomSmall textCenter "
              >
                <Typography variant="body2" align="center">
                  Contact
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* {FeaturedProperties.map((property) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            className="textCenter paddingBottomSmall"
            key={property.id}
          >
            <PropertyComponent
              type="featured"
              link
              id={property.id}
              image={property.data.featuredImage}
              address={
                property.data.streetAddress +
                ', ' +
                property.data.city +
                ', ' +
                property.data.state
              }
              street={property.data.streetAddress}
              location={property.data.city + ', ' + property.data.state}
              price={property.data.price}
              gain={property.data.maxGain}
              livingAreaSquareFootage={property.data.livingAreaSquareFootage}
              nonLiveableAreaSquareFootage={
                property.data.garageSF + property.data.deckSF
              }
              maxLotCoverage={property.data.maxLotCoverage}
              className="paddingBottomSmall"
            />
          </Grid>
        ))} */}
      </>
    );
  }
  return (
    <>
      <TopMenu {...props} />
      {!isAuthorized && <UnAuthorizedContentNew />}

      {isAuthorized && <AuthorizedContent />}
    </>
  );
}

export default Home;
