export default {
  HOME: "/",
  SEARCH: "/search",
  CLIENTS: "/clients",
  CLIENT: "/client",
  CLIENTPROPERTIES: "/client-properties",

  SETTINGS: "/settings",
  PROPERTY: "/property",
  MYPROPERTIES: "/properties"

};
