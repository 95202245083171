import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeroImage from '../static/images/home/hero-image.png';
import LazyHero from 'react-lazy-hero';
import { Hidden, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heroTextContainer: {
    maxWidth: '80%',
    margin: '0 auto',
  },
  HeroContainer: {
    marginTop: '6rem',
  },
}));
export default function HeroImageComponent(props) {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <LazyHero
          imageSrc={HeroImage}
          color="#000"
          opacity="0.3"
          minHeight="95vh"
          isCentered
          className={classes.HeroContainer}
        >
          <div className={classes.heroTextContainer}>
            <Typography variant="h3" className="textWhite paddingBottomMedium">
              Maximize Your Real Estate Investment
            </Typography>
            <Typography variant="h6" className="textWhite">
              ETHER is the real estate industry’s planning portal featuring
              accurate, real-time information on a property’s development
              potential and construction costs.
            </Typography>
          </div>
        </LazyHero>
      </Hidden>
      <Hidden mdUp>
        <LazyHero
          imageSrc={HeroImage}
          color="#000"
          opacity="0.3"
          minHeight="65vh"
          isCentered
          className={classes.HeroContainer}
        >
          <div className={classes.heroTextContainer}>
            <Typography variant="h3" className="textWhite paddingBottomMedium">
              Maximize Your Real Estate Investment
            </Typography>
            <Typography variant="h6" className="textWhite">
              ETHER is the real estate industry’s planning portal featuring
              accurate, real-time information on a property’s development
              potential and construction costs.
            </Typography>
          </div>
        </LazyHero>
      </Hidden>
    </>
  );
}
