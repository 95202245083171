import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useLocation, useHistory, Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Logo from '../static/images/general/logo.png';
import User from '../static/images/general/user.jpg';
import { Fragment } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { Alert } from '@material-ui/lab';

const NavigationLibrary = {
  '/': 0,
  '/search': 1,
  '/clients': 2,
  '/settings': 3,
};

function TopMenu(props) {
  console.log('Porps', props);
  const { pathname } = useLocation();
  const { isAuthorized, handleLogin, userData, handleProfileMenuOpen } = props;
  console.log(isAuthorized);
  let history = useHistory();
  const [activeMenu, setActiveMenu] = useState(pathname);
  const [notificationWindowVisible, setNotificationWindowVisible] =
    useState(false);

  console.log(activeMenu);
  function HomeMenuNoAva() {
    return (
      <Fragment>
        <Grid item xs={4} className="paddingLeftSmall"></Grid>
        <Grid item xs={2} md={2} lg={1} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <Badge
            badgeContent={3}
            color="error"
            onClick={() => {
              setNotificationWindowVisible(true);
            }}
          >
            <MailIcon />
          </Badge>
        </Grid>
      </Fragment>
    );
  }
  function HomeMenuAva() {
    return (
      <Fragment>
        <Grid item xs={4} className="paddingLeftSmall">
          <Link to="/settings">
            <Avatar alt="User One" src={userData.profilePic} />
          </Link>
        </Grid>
        <Grid item xs={2} md={2} lg={1} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <Badge
            badgeContent={3}
            color="error"
            onClick={() => {
              setNotificationWindowVisible(true);
            }}
          >
            <MailIcon />
          </Badge>
        </Grid>
      </Fragment>
    );
  }
  function HomeMenuBack() {
    return (
      <Fragment>
        <Grid item xs={4} className="paddingLeftSmall">
          <Link to={props.back} className="noUnderline">
            <IconButton
              edge="end"
              aria-label="back"
              aria-haspopup="true"
              color="inherit"
            >
              <ArrowBackIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item xs={2} md={2} lg={1} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <Badge
            badgeContent={3}
            color="error"
            onClick={() => {
              setNotificationWindowVisible(true);
            }}
          >
            <MailIcon />
          </Badge>
        </Grid>
      </Fragment>
    );
  }

  function HomeMenuOnclick() {
    return (
      <Fragment>
        <Grid item xs={4} className="paddingLeftSmall">
          <IconButton
            edge="end"
            aria-label="back"
            aria-haspopup="true"
            color="inherit"
            onClick={props.onBackClick}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={1} lg={1} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <Badge
            badgeContent={3}
            color="error"
            onClick={() => {
              setNotificationWindowVisible(true);
            }}
          >
            <MailIcon />
          </Badge>
        </Grid>
      </Fragment>
    );
  }
  function HomeMenuLogin() {
    return (
      <Fragment>
        <Grid item xs={4} className="textCenter"></Grid>
        <Grid item xs={2} md={1} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleLogin();
            }}
          >
            Login
          </Button>
        </Grid>
      </Fragment>
    );
  }

  function PropertyMenu() {
    return (
      <Fragment>
        <Grid item xs={4} className="paddingLeftSmall">
          <Link to="/settings">
            <Avatar alt="User One" src={userData.profilePic} />
          </Link>
        </Grid>
        <Grid item xs={2} className="textCenter">
          <Link to="/">
            <img src={Logo} className="topLogo" />
          </Link>
        </Grid>
        <Grid item xs={4} className="textRight paddingRightSmall">
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="propertyShare"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            {props.myProperty && <FavoriteIcon />}
            {!props.myProperty && <FavoriteBorderIcon />}
          </IconButton>
        </Grid>
      </Fragment>
    );
  }

  function NotificationWindow() {
    return (
      <div
        className="notificationContainer"
        onClick={() => {
          setNotificationWindowVisible(false);
        }}
      >
        <div className="notification">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={0}
            data-aos="fade"
            data-aos-duration="1000"
          >
            <Grid item xs={12} sm={12} md={12} lg={3} className="textCenter">
              <Typography
                variant="h5"
                gutterBottom
                data-aos="fade-up"
                align="center"
              >
                Notifications
              </Typography>
              <List dense className="clientList">
                <ListItem
                  key={'message'}
                  button
                  className="clientListItem"
                  onClick={() => {
                    history.push('/property/property-1');
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n1`}
                      src={
                        'https://t3.ftcdn.net/jpg/02/58/89/90/360_F_258899001_68CalsKTRk6PZQgWH9JhR4heBlncCko9.jpg'
                      }
                      className="clientAvatar"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={'placeholder'}
                    primary={'Property from Steve'}
                    secondary={
                      "Check out this property I have off market, I think you'd be interested"
                    }
                    secondaryTypographyProps={{
                      className: 'secondaryClientText',
                    }}
                  />
                </ListItem>
                <ListItem key={'message'} button className="clientListItem">
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n1`}
                      src={
                        'https://images.unsplash.com/photo-1569243478735-8fcf29052262?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
                      }
                      className="clientAvatar"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={'placeholder'}
                    primary={'New Message from Sarah'}
                    secondary={
                      'We just closed! Thank you so much for your help.'
                    }
                    secondaryTypographyProps={{
                      className: 'secondaryClientText',
                    }}
                  />
                </ListItem>
                <ListItem key={'message'} button className="clientListItem">
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n1`}
                      src={
                        'https://i.pinimg.com/originals/d5/b0/0c/d5b00c45a01fde2e2f5158fdd7bd3058.jpg'
                      }
                      className="clientAvatar"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={'placeholder'}
                    primary={'New Message from Carol'}
                    secondary={
                      'Do you have any available properties in Larkspur 4000 SF+?'
                    }
                    secondaryTypographyProps={{
                      className: 'secondaryClientText',
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  console.log('hh', activeMenu.indexOf('client-properties'), isAuthorized);
  return (
    <div className="topBarContainer paddingTopXSmall paddingBottomXSmall">
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        {activeMenu === '/' && isAuthorized && <HomeMenuNoAva />}
        {activeMenu === '/search' && isAuthorized && <HomeMenuOnclick />}

        {activeMenu === '/' && !isAuthorized && <HomeMenuLogin />}
        {activeMenu === '/clients' && isAuthorized && <HomeMenuAva />}
        {activeMenu === '/properties' && isAuthorized && <HomeMenuAva />}
        {activeMenu === '/settings' && isAuthorized && <HomeMenuAva />}
        {activeMenu.indexOf('client/') > -1 && isAuthorized && <HomeMenuBack />}
        {activeMenu.indexOf('client-properties') > -1 && isAuthorized && (
          <HomeMenuBack />
        )}

        {activeMenu.indexOf('property') > -1 && !isAuthorized && (
          <HomeMenuNoAva />
        )}
        {activeMenu.indexOf('property') > -1 && isAuthorized && (
          <PropertyMenu />
        )}
      </Grid>
      {notificationWindowVisible && <NotificationWindow />}
    </div>
  );
}
export default TopMenu;
