import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinkIcon from '@material-ui/icons/Link';
const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

function createData(title, link) {
  return { title, link };
}

export default function Resources(props) {
  const classes = useStyles();
  const { resources } = props;

  let rows = [];

  for (var i = 0; i < resources.length; i++) {
    rows.push(createData(resources[i].title, resources[i].link));
  }
  return (
    <TableContainer component={Paper} className="marginBottomLarge">
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Links</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={row.link + i.toString()}>
              <TableCell component="th" scope="row">
                <a className="linkAnchor" href={row.link}>
                  <LinkIcon className="linkIcon" />
                  {row.title}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
