import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import BottomNavigationMenu from "../components/BottomNavigationMenu";
import TopMenu from "../components/TopMenu";

import PropertyCarousel from "../components/PropertyCarousel";
import { isLoggedIn, getClientInfo } from "../utils/auth";

function ClientProperties(props) {
  const { isAuthorized } = props;
  const { pathname } = useLocation();
  var clientId = /[^/]*$/.exec(pathname)[0];

  console.log("Is authorized: ", clientId);
  let history = useHistory();
  const [checked, setChecked] = React.useState([1]);
  const [userData, setUserData] = useState(isLoggedIn());
  const [clientData, setClientData] = useState(getClientInfo(clientId))

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    if (!isAuthorized) {
      history.push("/");
    }
  }, []);
  return (
    
    <>
    
    {userData && (
    <>
      <TopMenu {...props} back={`/client/${clientData.id}`} />
      {clientData.properties.length && (
        <PropertyCarousel properties={clientData.properties}/>
      )}

{!clientData.properties.length && (
     <Typography
     variant="h4"
     gutterBottom
     data-aos="fade-up"
     align="center"
     className="paddingTopLarge"
   >
     No Saved Properties
   </Typography>
)}
      <BottomNavigationMenu />
      </>
    )}
    </>
  );
}

export default ClientProperties;
