import React, { useState, Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { useLocation, useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Logo from '../static/images/general/logo.png';
import User from '../static/images/general/user.jpg';

import { EstimatedConstructionCostsData } from '../utils/defaultData';
import { formatNumber } from '../utils/formatter';

function ConstructionCalculator(props) {
  const { constructionArea } = props;
  const constructionAreaData = EstimatedConstructionCostsData[constructionArea];

  const [costDataMap, setCostDataMap] = useState({});
  const [multiplierMap, setMultiplierMap] = useState({});

  const [estimatedCostDataMap, setEstimatedCostDataMap] = useState({
    high: 0,
    median: 0,
    low: 0,
  });

  useEffect(() => {
    let _costDataMap = {};
    let _multiplierMap = {};
    constructionAreaData.forEach(function (constructionType, index) {
      _costDataMap[constructionType.id] = 0;
      _multiplierMap[constructionType.id] = {
        low: constructionType.low,
        median: constructionType.median,
        high: constructionType.high,
      };
    });
    setCostDataMap(_costDataMap);
    setMultiplierMap(_multiplierMap);
  }, []);
  let newEstimatedCostDataMap = { low: 0, median: 0, high: 0 };

  const calculateEstimatedCosts = (newData) => {
    for (const [key, value] of Object.entries(newData)) {
      newEstimatedCostDataMap.low += value * multiplierMap[key].low;
      newEstimatedCostDataMap.median += value * multiplierMap[key].median;
      newEstimatedCostDataMap.high += value * multiplierMap[key].high;
    }
    setEstimatedCostDataMap(newEstimatedCostDataMap);
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let newVal = e.target.value;
    if (!newVal || newVal.length === 0) {
      newVal = 0;
    }
    let newData = {
      ...costDataMap,
      [id]: +newVal,
    };
    setCostDataMap({
      ...costDataMap,
      [id]: +newVal,
    });
    calculateEstimatedCosts(newData);
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className="marginBottomLarge paddingTopSmall greyBackground"
    >
      {constructionAreaData.map((constructionType) => (
        <Fragment key={constructionType.id}>
          <Grid
            item
            xs={10}
            sm={6}
            md={6}
            lg={3}
            className="textLeft paddingBottomSmall"
          >
            {constructionType.name}
          </Grid>
          <Grid
            item
            xs={2}
            sm={6}
            md={6}
            lg={3}
            className="textRight paddingBottomSmall"
          >
            <TextField
              id={constructionType.id}
              className="constructionCalculatorInput"
              onChange={handleInputChange}
              label="Sq Ft"
              value={costDataMap[constructionType.id]}
            />
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12} className="textCenter paddingBottomSmall">
        <Typography variant="h5">Total Cost</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">High:</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">
          ${formatNumber(estimatedCostDataMap.high)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">Median:</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">
          ${formatNumber(estimatedCostDataMap.median)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">Low:</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        className="textCenter paddingBottomSmall"
      >
        <Typography variant="body1">
          ${formatNumber(estimatedCostDataMap.low)}
        </Typography>
      </Grid>
    </Grid>
  );
}
export default ConstructionCalculator;
