export const DefaultUserData = [
  { name: 'Sheila Casa' },
  { email: 'sheila@casa.com' },
  { city: 'San Francisco' },
  { state: 'CA' },
  { dreNumber: '0277410' },
  {
    profilePic:
      'https://d3ftgi1u5oppc5.cloudfront.net/pics/realtor/634340/1356472//maxwidth/920/',
  },
  {
    savedProperties: [
      {
        id: 'property-6',
        data: {
          livingAreaSquareFootage: 921,
          lotSquareFeet: 8581.8,
          maxLotCoverage: 2145.45,
          garageSF: 0,
          deckSF: 120,
          price: 953100,
          bedroom: 1,
          bathroom: 1,
          livingUnits: 1,
          streetAddress: '155 Summit Drive',
          city: 'Corte Madera',
          state: 'CA',
          zip: '94925',
          lat: 37.92225,
          lng: -122.535,
          zoningCode: 'R-1-A Low Density Residential District',
          constructionArea: 'areaOne',
          featuredImage:
            'https://photos.zillowstatic.com/fp/1fd839c0e03674465874a18417d27090-uncropped_scaled_within_1536_1152.webp',
          propertyCharacteristics: [
            { title: 'Owner', data: 'Yaron Palfy' },
            { title: 'APN', data: '025-083-65' },
            { title: 'Construction Year', data: 1931 },
            { title: 'Max Floor Area Ratio', data: 3432.72 },
            { title: 'Max Lot Coverage', data: 2145.45 },
            { title: 'Max Lot Coverage Percentage', data: 0.4 },
            { title: 'Garage Area Square Footage', data: 0 },
            { title: 'Deck/Patio Square Footage', data: 120 },
            { title: 'Slope', data: 51 },
            { title: 'Slope Requirement', data: 'Yes, On side yard' },
            { title: 'Waterway Conservation', data: 'No' },
            { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

            { title: 'Flood Plane', data: 'No' },
          ],
        },
      },
      {
        id: 'property-1',
        data: {
          livingAreaSquareFootage: 2032,
          lotSquareFeet: 6040,
          maxLotCoverage: 3516,
          garageSF: 560,
          deckSF: 766,
          price: 2143700,
          bedroom: 4,
          bathroom: 3,
          livingUnits: 1,
          streetAddress: '360 Corte Madera Ave.',
          city: 'Corte Madera',
          state: 'CA',
          zip: '94925',
          lat: 37.92296,
          lng: -122.53062,
          zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
          constructionArea: 'areaOne',
          featuredImage:
            'https://photos.zillowstatic.com/fp/6313a2a3bc3512a4e15e87e7cdd8e363-uncropped_scaled_within_1536_1152.webp',
          propertyCharacteristics: [
            { title: 'Owner', data: 'Adam Maldonado' },
            { title: 'APN', data: '025-095-18' },
            { title: 'Construction Year', data: 1959 },
            { title: 'Max Floor Area Ratio', data: 2904 },
            { title: 'Max Lot Coverage', data: 2416 },
            { title: 'Max Lot Coverage Percentage', data: 0.4 },
            { title: 'Garage Area Square Footage', data: 560 },
            { title: 'Deck/Patio Square Footage', data: 766 },
            { title: 'Slope', data: 13.2 },
            { title: 'Slope Requirement', data: 'Yes, On side yard' },
            { title: 'Waterway Conservation', data: 'No' },
            { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

            { title: 'Flood Plane', data: 'No' },
          ],
        },
      },
    ],
  },
  {
    clients: [
      {
        id: 'client-1',
        name: 'Nancy Buys',
        phone: '+13334534543',
        email: 'nancy@nancybuys.com',
        location: 'Corte Madera, CA',
        profilePic:
          'https://images.unsplash.com/photo-1569243478735-8fcf29052262?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
        properties: [],
      },
      {
        id: 'client-2',
        name: 'Jeff Porter',
        phone: '+13334534543',
        email: 'jeff@porter.com',
        location: 'Larkspur, CA',
        profilePic:
          'https://t3.ftcdn.net/jpg/02/58/89/90/360_F_258899001_68CalsKTRk6PZQgWH9JhR4heBlncCko9.jpg',
        properties: [],
      },
      {
        id: 'client-3',
        name: 'Margaret Thatcher',
        phone: '+13334534543',
        email: 'marg@that.com',
        location: 'Corte Madera, CA',
        profilePic:
          'https://i.pinimg.com/originals/d5/b0/0c/d5b00c45a01fde2e2f5158fdd7bd3058.jpg',
        properties: [],
      },
    ],
  },
];

export const ClientData = [
  {
    id: 'client-1',
    name: 'Nancy Buys',
    email: 'nancy@nancybuys.com',
    profilePic:
      'https://i.pinimg.com/originals/d5/b0/0c/d5b00c45a01fde2e2f5158fdd7bd3058.jpg',
    properties: [],
  },
];

export const EstimatedConstructionCostsData = {
  areaOne: [
    {
      id: 'newAddition',
      name: 'New Addition',
      low: 280,
      median: 490,
      high: 700,
    },
    {
      id: 'newBathroom',
      name: 'New Bathroom',
      low: 320,
      median: 560,
      high: 800,
    },
    {
      id: 'newKitchen',
      name: 'New Kitchen',
      low: 300,
      median: 525,
      high: 700,
    },
    {
      id: 'remodelGeneral',
      name: 'Remodel General',
      low: 160,
      median: 280,
      high: 400,
    },
    {
      id: 'remodelBathroom',
      name: 'Remodel Bathroom',
      low: 200,
      median: 350,
      high: 500,
    },
    {
      id: 'remodelKitchen',
      name: 'Remodel Kitchen',
      low: 180,
      median: 315,
      high: 450,
    },
  ],
};
export const ZoningInformation = {
  'R-1 - Medium Density (Less then 7500 sq.ft.)': {
    data: [
      { title: 'Lot SF', data: 'Less than 7500' },
      {
        title: 'Max Floor Area Ratio',
        data: 'Lot S.F. *0.1+(2300)',
      },
      {
        title: 'Max Lot Coverage',
        data: 0.4,
      },
      { title: 'Height', data: 30 },
      {
        title: 'Frontyard Setback',
        data: 15,
      },
      {
        title: 'Corner Front/Sideyard Setback',
        data: 10,
      },
      {
        title: 'Sideyard Setback',
        data: 5,
      },
      {
        title: 'Rear yard Setback',
        data: 25,
      },
      {
        title: 'Parking Requirement',
        data: '2 spaces, 1 must be covered',
      },
      { title: 'Max Non Permeable', data: 'N/A' },
      { title: 'Natural State', data: 'N/A' },

      {
        title: 'Bonus S.F. in addition to Max. Floor Area or Lot Coverage',
        data: '2 Bedroom ADU = 1000 S.F. /n 1 Bedroom ADU = 850 S.F.',
      },
    ],
    resources: [
      {
        title: 'R1 Planning Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.08RREDI',
      },
      {
        title: 'Development Standards',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/294/Residential-Zoning-District-Development-Standards-PDF?bidId=',
      },
      {
        title: 'Parking Standards',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.20OREPALO',
      },
      {
        title: 'Calculating FAR',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/289/Floor-Area-Ratio-Handout-PDF?bidId=',
      },
      {
        title: 'Setbacks and Lot Coverage',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.24GEPREX',
      },
      { title: 'Deck Standards', link: 'http://google.com' },
      { title: 'Flood Code', link: 'http://google.com' },
      { title: 'Fire Restrictions', link: 'http://google.com' },
      {
        title: 'ADU Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.31ACDWUNJUACDWUN',
      },
    ],
  },
  'R-1 - Medium Density (Greater then 7500 sq.ft.)': {
    data: [
      { title: 'Lot SF', data: 'Greater than 7500' },
      {
        title: 'Max Floor Area Ratio',
        data: 0.4,
      },
      {
        title: 'Max Lot Coverage',
        data: 0.4,
      },
      { title: 'Height', data: 30 },
      {
        title: 'Frontyard Setback',
        data: 20,
      },
      {
        title: 'Corner Front/Sideyard Setback',
        data: 15,
      },
      {
        title: 'Sideyard Setback',
        data: 6,
      },
      {
        title: 'Rear yard Setback',
        data: 25,
      },
      {
        title: 'Parking Requirement',
        data: '2 spaces, 1 must be covered',
      },
      { title: 'Max Non Permeable', data: 'N/A' },
      { title: 'Natural State', data: 'N/A' },

      {
        title: 'Bonus S.F. in addition to Max. Floor Area or Lot Coverage',
        data: '2 Bedroom ADU = 1000 S.F. /n 1 Bedroom ADU = 850 S.F.',
      },
    ],
    resources: [
      {
        title: 'R1 Planning Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.08RREDI',
      },
      {
        title: 'Development Standards',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/294/Residential-Zoning-District-Development-Standards-PDF?bidId=',
      },
      {
        title: 'Parking Standards',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.20OREPALO',
      },
      {
        title: 'Calculating FAR',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/289/Floor-Area-Ratio-Handout-PDF?bidId=',
      },
      {
        title: 'Setbacks and Lot Coverage',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.24GEPREX',
      },
      { title: 'Deck Standards', link: 'http://google.com' },
      { title: 'Flood Code', link: 'http://google.com' },
      { title: 'Fire Restrictions', link: 'http://google.com' },
      {
        title: 'ADU Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.31ACDWUNJUACDWUN',
      },
    ],
  },
  'R-1-A Low Density Residential District': {
    data: [
      { title: 'Lot SF', data: '20000 SF min' },
      {
        title: 'Max Floor Area Ratio',
        data: 0.4,
      },
      {
        title: 'Max Lot Coverage',
        data: 0.25,
      },
      { title: 'Height', data: 'No Data' },
      {
        title: 'Frontyard Setback',
        data: 'Flexible',
      },
      {
        title: 'Corner Front/Sideyard Setback',
        data: 20,
      },
      {
        title: 'Sideyard Setback',
        data: 10,
      },
      {
        title: 'Rear yard Setback',
        data: 25,
      },
      {
        title: 'Parking Requirement',
        data: 'No Data',
      },
      { title: 'Max Non Permeable', data: 'N/A' },
      { title: 'Natural State', data: 'N/A' },

      {
        title: 'Bonus S.F. in addition to Max. Floor Area or Lot Coverage',
        data: '2 Bedroom ADU = 1000 S.F. /n 1 Bedroom ADU = 850 S.F.',
      },
    ],
    resources: [
      {
        title: 'R1 Planning Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.08RREDI',
      },
      {
        title: 'Development Standards',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/294/Residential-Zoning-District-Development-Standards-PDF?bidId=',
      },
      {
        title: 'Parking Standards',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.20OREPALO',
      },
      {
        title: 'Calculating FAR',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/289/Floor-Area-Ratio-Handout-PDF?bidId=',
      },
      {
        title: 'Setbacks and Lot Coverage',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.24GEPREX',
      },
      { title: 'Deck Standards', link: 'http://google.com' },
      { title: 'Flood Code', link: 'http://google.com' },
      { title: 'Fire Restrictions', link: 'http://google.com' },
      {
        title: 'ADU Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.31ACDWUNJUACDWUN',
      },
    ],
  },
  'R-1': {
    data: [
      { title: 'Lot SF', data: 'No Data' },
      {
        title: 'Max Floor Area Ratio',
        data: 0.4,
      },
      {
        title: 'Max Lot Coverage',
        data: 0.4,
      },
      {
        title: 'Slope',
        data: '10% slope, max FAR decreases by .01 for each 1% above 10% or min. allowable of .05',
      },
      { title: 'Height', data: 30 },
      {
        title: 'Frontyard Setback',
        data: 20,
      },
      {
        title: 'Corner Front/Sideyard Setback',
        data: 10,
      },
      {
        title: 'Sideyard Setback',
        data: 5,
      },
      {
        title: 'Rear yard Setback',
        data: 15,
      },
      {
        title: 'Parking Requirement',
        data: '2+2 1 space must be covered',
      },
      { title: 'Max Non Permeable', data: 'N/A' },
      { title: 'Natural State', data: 'N/A' },

      {
        title: 'Bonus S.F. in addition to Max. Floor Area or Lot Coverage',
        data: 'No Data',
      },
    ],
    resources: [
      {
        title: 'R1 Planning Code',
        link: 'https://larkspur.municipal.codes/Code',
      },
      {
        title: 'Development Standards',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/294/Residential-Zoning-District-Development-Standards-PDF?bidId=',
      },
      {
        title: 'Parking Standards',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.20OREPALO',
      },
      {
        title: 'Calculating FAR',
        link: 'https://www.townofcortemadera.org/DocumentCenter/View/289/Floor-Area-Ratio-Handout-PDF?bidId=',
      },
      {
        title: 'Setbacks and Lot Coverage',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.24GEPREX',
      },
      { title: 'Deck Standards', link: 'http://google.com' },
      { title: 'Flood Code', link: 'http://google.com' },
      { title: 'Fire Restrictions', link: 'http://google.com' },
      {
        title: 'ADU Code',
        link: 'https://library.municode.com/ca/corte_madera/codes/code_of_ordinances?nodeId=TIT18ZO_CH18.31ACDWUNJUACDWUN',
      },
    ],
  },
};

export const FeaturedProperties = [
  {
    id: 'property-1a',
    data: {
      livingAreaSquareFootage: 2032,
      lotSquareFeet: 6040,
      maxLotCoverage: 3516,
      garageSF: 560,
      deckSF: 766,
      price: 2143700,
      bedroom: 4,
      bathroom: 3,
      livingUnits: 1,
      streetAddress: '360 Corte Madera Ave.',
      lat: 37.92296,
      lng: -122.53062,
      city: 'Corte Madera',
      state: 'CA',
      zip: '94925',
      zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
      constructionArea: 'areaOne',
      featuredImage:
        'https://photos.zillowstatic.com/fp/6313a2a3bc3512a4e15e87e7cdd8e363-uncropped_scaled_within_1536_1152.webp',
      propertyCharacteristics: [
        { title: 'Owner', data: 'Adam Maldonado' },
        { title: 'APN', data: '025-095-18' },
        { title: 'Construction Year', data: 1959 },
        { title: 'Max Floor Area Ratio', data: 2904 },
        { title: 'Max Lot Coverage', data: 2416 },
        { title: 'Max Lot Coverage Percentage', data: 0.4 },
        { title: 'Garage Area Square Footage', data: 560 },
        { title: 'Deck/Patio Square Footage', data: 766 },
        { title: 'Slope', data: 13.2 },
        { title: 'Slope Requirement', data: 'Yes, On side yard' },
        { title: 'Waterway Conservation', data: 'No' },
        { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

        { title: 'Flood Plane', data: 'No' },
      ],
      propertyPotential: [
        { title: 'Max Lot coverage', data: 2416 },
        { title: 'Max FAR (Floor Area Ratio)', data: 2904 },
        {
          title: 'Potential Issues',
          alert: true,
          data: ['Slope requirement on side yard. Surveyor may be needed.'],
        },
      ],
    },
  },
  {
    id: 'property-2a',
    data: {
      livingAreaSquareFootage: 1689,
      lotSquareFeet: 7293,
      maxLotCoverage: 2917.2,
      garageSF: 459,
      deckSF: 0,
      price: 1645200,
      bedroom: 3,
      bathroom: 1,
      livingUnits: 1,
      streetAddress: '38 Lakeside Dr.',
      city: 'Corte Madera',
      state: 'CA',
      zip: '94925',
      lat: 37.92918,
      lng: -122.52085,
      zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
      constructionArea: 'areaOne',
      featuredImage:
        'https://photos.zillowstatic.com/fp/e6921439d533c975a6539df1d3207dca-uncropped_scaled_within_1536_1152.webp',
      propertyCharacteristics: [
        { title: 'Owner', data: 'David Peck' },
        { title: 'APN', data: '025-095-19' },
        { title: 'Construction Year', data: 1953 },
        { title: 'Max Floor Area Ratio', data: 3029.3 },
        { title: 'Max Lot Coverage', data: 2917.2 },
        { title: 'Max Lot Coverage Percentage', data: 0.4 },
        { title: 'Garage Area Square Footage', data: 459 },
        { title: 'Deck/Patio Square Footage', data: 0 },
        { title: 'Slope', data: 1.8 },
        { title: 'Slope Requirement', data: 'No' },
        { title: 'Waterway Conservation', data: 'No' },
        { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

        { title: 'Flood Plane', data: 'Yes Zone AE, Need to raise house' },
      ],
      propertyPotential: [
        { title: 'Max Lot coverage', data: 2917.2 },
        { title: 'Max FAR (Floor Area Ratio)', data: 3029.3 },
        {
          title: 'Potential Issues',
          alert: true,
          data: ['Zone AE, Need to raise house.'],
        },
      ],
    },
  },
  {
    id: 'property-3a',
    data: {
      livingAreaSquareFootage: 2032,
      lotSquareFeet: 6532,
      maxLotCoverage: 2483.6,
      garageSF: 0,
      deckSF: 0,
      price: 2114500,
      bedroom: 4,
      bathroom: 3,
      livingUnits: 1,
      streetAddress: '38 Birch Ave',
      city: 'Corte Madera',
      state: 'CA',
      zip: '94925',
      lat: 37.93229,
      lng: -122.52153,
      zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
      constructionArea: 'areaOne',
      featuredImage:
        'https://photos.zillowstatic.com/fp/47bd0a2d98242e98fe5970b65611c97d-uncropped_scaled_within_1536_1152.webp',
      propertyCharacteristics: [
        { title: 'Owner', data: 'Adam Maldonado' },
        { title: 'APN', data: '025-095-18' },
        { title: 'Construction Year', data: 1959 },
        { title: 'Max Floor Area Ratio', data: 2953.2 },
        { title: 'Max Lot Coverage', data: 2483.6 },
        { title: 'Max Lot Coverage Percentage', data: 0.4 },
        { title: 'Garage SF', data: 560 },
        { title: 'Deck/Patio SF', data: 766 },
        { title: 'Slope', data: 1.7 },
        { title: 'Slope Requirement', data: 'No' },
        { title: 'Waterway Conservation', data: 'No' },
        { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

        {
          title: 'Flood Plane',
          data: 'Yes Zone AE, Need to reduce net lot SF, 6209 Net lot area after 6.0 NAVD reduction',
        },
      ],
      propertyPotential: [
        { title: 'Max Lot coverage', data: 2483.6 },
        { title: 'Max FAR (Floor Area Ratio)', data: 2953.2 },
        {
          title: 'Potential Issues',
          alert: true,
          data: [
            'Yes Zone AE, Need to reduce net lot SF, 6209 Net lot area after 6.0 NAVD reduction',
          ],
        },
      ],
    },
  },
  // {
  //   id: 'property-4a',
  //   data: {
  //     livingAreaSquareFootage: 2032,
  //     lotSquareFeet: 6372,
  //     maxLotCoverage: 2483.6,
  //     price: 1625100,
  //     bedroom: 4,
  //     bathroom: 3,
  //     livingUnits: 1,
  //     streetAddress: '26 Buena Vista',
  //     city: 'Corte Madera',
  //     state: 'CA',
  //     zip: '94925',
  //     lat: 37.92227,
  //     lng: -122.52277,
  //     zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://photos.zillowstatic.com/fp/a33d057cc44909fbffb3524eed4b436b-uncropped_scaled_within_1536_1152.webp',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'Adam Maldonado' },
  //       { title: 'APN', data: '025-095-18' },
  //       { title: 'Construction Year', data: 1959 },
  //       { title: 'Max Floor Area Ratio', data: 2937.2 },
  //       { title: 'Max Lot Coverage', data: 2483.6 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage SF', data: 560 },
  //       { title: 'Deck/Patio SF', data: 766 },
  //       { title: 'Slope', data: 16.8 },
  //       { title: 'Slope Requirement', data: 'No' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 2483.6 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 2937.2 },
  //       {
  //         title: 'Potential Issues',
  //         alert: true,
  //         data: ['Wildland Urban Interface (WUI) Zone'],
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 'property-5a',
  //   data: {
  //     livingAreaSquareFootage: 1580,
  //     lotSquareFeet: 9506,
  //     maxLotCoverage: 3802.4,
  //     price: 1866600,
  //     bedroom: 4,
  //     bathroom: 2,
  //     livingUnits: 1,
  //     streetAddress: '78 Granada Dr.',
  //     city: 'Corte Madera',
  //     state: 'CA',
  //     zip: '94925',
  //     lat: 37.9147416,
  //     lng: -122.5052764,
  //     zoningCode: 'R-1 - Medium Density (Greater then 7500 sq.ft.)',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://p.rdcpix.com/v01/l0b1fa045-m0xd-w1020_h770_q80.jpg',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'Chad Gerber' },
  //       { title: 'APN', data: '026-134-09' },
  //       { title: 'Construction Year', data: 1957 },
  //       { title: 'Max Floor Area Ratio', data: 3802.4 },
  //       { title: 'Max Lot Coverage', data: 3802.4 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage Area Square Footage', data: 437 },
  //       { title: 'Deck/Patio Square Footage', data: 0 },
  //       { title: 'Slope', data: 20.5 },
  //       { title: 'Slope Requirement', data: 'Yes' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 3802.4 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 3802.4 },
  //       { title: 'Potential Issues', alert: true, data: ['Slope requirement'] },
  //     ],
  //   },
  // },
  // {
  //   id: 'property-6a',
  //   data: {
  //     livingAreaSquareFootage: 921,
  //     lotSquareFeet: 8581.8,
  //     maxLotCoverage: 2145.45,
  //     price: 953100,
  //     bedroom: 1,
  //     bathroom: 1,
  //     livingUnits: 1,
  //     streetAddress: '155 Summit Drive',
  //     city: 'Corte Madera',
  //     state: 'CA',
  //     zip: '94925',
  //     lat: 37.92225,
  //     lng: -122.535,
  //     zoningCode: 'R-1-A Low Density Residential District',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://photos.zillowstatic.com/fp/1fd839c0e03674465874a18417d27090-uncropped_scaled_within_1536_1152.webp',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'Yaron Palfy' },
  //       { title: 'APN', data: '025-083-65' },
  //       { title: 'Construction Year', data: 1931 },
  //       { title: 'Max Floor Area Ratio', data: 3432.72 },
  //       { title: 'Max Lot Coverage', data: 2145.45 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage Area Square Footage', data: 0 },
  //       { title: 'Deck/Patio Square Footage', data: 120 },
  //       { title: 'Slope', data: 51 },
  //       { title: 'Slope Requirement', data: 'Yes, On side yard' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 2145.45 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 3432.72 },
  //       {
  //         title: 'Potential Issues',
  //         alert: true,
  //         data: ['Wildland Urban Interface (WUI) Zone'],
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 'property-7a',
  //   data: {
  //     livingAreaSquareFootage: 978,
  //     lotSquareFeet: 7500,
  //     maxLotCoverage: 3000,
  //     price: 1659200,
  //     bedroom: 2,
  //     bathroom: 1,
  //     livingUnits: 1,
  //     streetAddress: '116 Monte Vista Ave',
  //     city: 'Larkspur',
  //     state: 'CA',
  //     zip: '94939',
  //     lat: 37.9319942,
  //     lng: -122.5340194,
  //     zoningCode: 'R-1',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://photos.zillowstatic.com/fp/be8bcbb0938cfc3d7010c22820b2ef76-uncropped_scaled_within_1536_1152.webp',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'John Maxwell' },
  //       { title: 'APN', data: '021-171-09' },
  //       { title: 'Construction Year', data: 1941 },
  //       { title: 'Max Floor Area Ratio', data: 3000 },
  //       { title: 'Max Lot Coverage', data: 3000 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage Area Square Footage', data: 0 },
  //       { title: 'Deck/Patio Square Footage', data: 40 },
  //       { title: 'Slope', data: 6 },
  //       { title: 'Slope Requirement', data: 'Yes, On side yard' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 3000 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 3000 },
  //       {
  //         title: 'Potential Issues',
  //         alert: true,
  //         data: ['Slope requirement on side yard, surveyor may be needded'],
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 'property-8a',
  //   data: {
  //     livingAreaSquareFootage: 2244,
  //     lotSquareFeet: 9800,
  //     maxLotCoverage: 3920,
  //     price: 2226500,
  //     bedroom: 4,
  //     bathroom: 2.5,
  //     livingUnits: 1,
  //     streetAddress: '40 Lark Ct',
  //     city: 'Larkspur',
  //     state: 'CA',
  //     zip: '94939',
  //     lat: 37.9273574,
  //     lng: -122.5328583,
  //     zoningCode: 'R-1',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/suburban-house-royalty-free-image-1584972559.jpg?crop=1.00xw:0.752xh;0,0.120xh&resize=1200:*',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'Barbara Freitas' },
  //       { title: 'APN', data: '021-142-24' },
  //       { title: 'Construction Year', data: 1967 },
  //       { title: 'Max Floor Area Ratio', data: 2548 },
  //       { title: 'Max Lot Coverage', data: 3920 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage Area Square Footage', data: 499 },
  //       { title: 'Deck/Patio Square Footage', data: 0 },
  //       { title: 'Slope', data: 23.1 },
  //       { title: 'Slope Requirement', data: 'Yes' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

  //       { title: 'Natural State', data: 48.1 },
  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 3920 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 2548 },
  //       {
  //         title: 'Potential Issues',
  //         alert: true,
  //         data: ['Wildland Urban Interface'],
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 'property-9a',
  //   data: {
  //     livingAreaSquareFootage: 1519,
  //     lotSquareFeet: 8250,
  //     maxLotCoverage: 2145,
  //     price: 1897400,
  //     bedroom: 3,
  //     bathroom: 2,
  //     livingUnits: 1,
  //     streetAddress: '60 Elm Ave',
  //     city: 'Larkspur',
  //     state: 'CA',
  //     zip: '94939',
  //     lat: 37.9314745,
  //     lng: -122.5294973,
  //     zoningCode: 'R-1',
  //     constructionArea: 'areaOne',
  //     featuredImage:
  //       'https://photos.zillowstatic.com/fp/8eee900b27e9528dfe5d62ecfda4bf9b-uncropped_scaled_within_1536_1152.webp',
  //     propertyCharacteristics: [
  //       { title: 'Owner', data: 'Ross Murry' },
  //       { title: 'APN', data: '021-223-29' },
  //       { title: 'Construction Year', data: 1932 },
  //       { title: 'Max Floor Area Ratio', data: 2310 },
  //       { title: 'Max Lot Coverage', data: 2145 },
  //       { title: 'Max Lot Coverage Percentage', data: 0.4 },
  //       { title: 'Garage Area Square Footage', data: 617 },
  //       { title: 'Deck/Patio Square Footage', data: 522 },
  //       { title: 'Slope', data: 21.6 },
  //       { title: 'Slope Requirement', data: 'Yes' },
  //       { title: 'Waterway Conservation', data: 'No' },
  //       { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

  //       { title: 'Natural State', data: 46.6 },
  //       { title: 'Flood Plane', data: 'No' },
  //     ],
  //     propertyPotential: [
  //       { title: 'Max Lot coverage', data: 2145 },
  //       { title: 'Max FAR (Floor Area Ratio)', data: 2310 },
  //       {
  //         title: 'Potential Issues',
  //         alert: true,
  //         data: ['Wildland Urban Interface'],
  //       },
  //     ],
  //   },
  // },
];

export const PropertyData = {
  'property-1': {
    livingAreaSquareFootage: 2032,
    lotSquareFeet: 6040,
    maxLotCoverage: 3516,
    garageSF: 560,
    deckSF: 766,
    price: 2143700,
    bedroom: 4,
    bathroom: 3,
    livingUnits: 1,
    streetAddress: '360 Corte Madera Ave.',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.92296,
    lng: -122.53062,
    zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/6313a2a3bc3512a4e15e87e7cdd8e363-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Adam Maldonado' },
      { title: 'APN', data: '025-095-18' },
      { title: 'Construction Year', data: 1959 },
      { title: 'Max Floor Area Ratio', data: 2904 },
      { title: 'Max Lot Coverage', data: 2416 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 560 },
      { title: 'Deck/Patio Square Footage', data: 766 },
      { title: 'Slope', data: 13.2 },
      { title: 'Slope Requirement', data: 'Yes, On side yard' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2416 },
      { title: 'Max FAR (Floor Area Ratio)', data: 2904 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Slope requirement on side yard. Surveyor may be needed.'],
      },
    ],
  },
  'property-2': {
    livingAreaSquareFootage: 1689,
    lotSquareFeet: 7293,
    maxLotCoverage: 2917.2,
    garageSF: 459,
    deckSF: 0,
    price: 1645200,
    bedroom: 3,
    bathroom: 1,
    livingUnits: 1,
    streetAddress: '38 Lakeside Dr.',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.92918,
    lng: -122.52085,
    zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/e6921439d533c975a6539df1d3207dca-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'David Peck' },
      { title: 'APN', data: '025-095-19' },
      { title: 'Construction Year', data: 1953 },
      { title: 'Max Floor Area Ratio', data: 3029.3 },
      { title: 'Max Lot Coverage', data: 2917.2 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 459 },
      { title: 'Deck/Patio Square Footage', data: 0 },
      { title: 'Slope', data: 1.8 },
      { title: 'Slope Requirement', data: 'No' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

      { title: 'Flood Plane', data: 'Yes Zone AE, Need to raise house' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2917.2 },
      { title: 'Max FAR (Floor Area Ratio)', data: 3029.3 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Zone AE, Need to raise house.'],
      },
    ],
  },
  'property-3': {
    livingAreaSquareFootage: 2032,
    lotSquareFeet: 6532,
    maxLotCoverage: 2483.6,
    garageSF: 0,
    deckSF: 0,
    price: 2114500,
    bedroom: 4,
    bathroom: 3,
    livingUnits: 1,
    streetAddress: '38 Birch Ave',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.93229,
    lng: -122.52153,
    zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/47bd0a2d98242e98fe5970b65611c97d-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Adam Maldonado' },
      { title: 'APN', data: '025-095-18' },
      { title: 'Construction Year', data: 1959 },
      { title: 'Max Floor Area Ratio', data: 2953.2 },
      { title: 'Max Lot Coverage', data: 2483.6 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage SF', data: 560 },
      { title: 'Deck/Patio SF', data: 766 },
      { title: 'Slope', data: 1.7 },
      { title: 'Slope Requirement', data: 'No' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

      {
        title: 'Flood Plane',
        data: 'Yes Zone AE, Need to reduce net lot SF, 6209 Net lot area after 6.0 NAVD reduction',
      },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2483.6 },
      { title: 'Max FAR (Floor Area Ratio)', data: 2953.2 },
      {
        title: 'Potential Issues',
        alert: true,
        data: [
          'Yes Zone AE, Need to reduce net lot SF, 6209 Net lot area after 6.0 NAVD reduction',
        ],
      },
    ],
  },
  'property-4': {
    livingAreaSquareFootage: 2032,
    lotSquareFeet: 6372,
    maxLotCoverage: 2483.6,
    garageSF: 0,
    deckSF: 0,
    price: 1625100,
    bedroom: 4,
    bathroom: 3,
    livingUnits: 1,
    streetAddress: '26 Buena Vista',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.92227,
    lng: -122.52277,
    zoningCode: 'R-1 - Medium Density (Less then 7500 sq.ft.)',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/a33d057cc44909fbffb3524eed4b436b-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Adam Maldonado' },
      { title: 'APN', data: '025-095-18' },
      { title: 'Construction Year', data: 1959 },
      { title: 'Max Floor Area Ratio', data: 2937.2 },
      { title: 'Max Lot Coverage', data: 2483.6 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage SF', data: 560 },
      { title: 'Deck/Patio SF', data: 766 },
      { title: 'Slope', data: 16.8 },
      { title: 'Slope Requirement', data: 'No' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2483.6 },
      { title: 'Max FAR (Floor Area Ratio)', data: 2937.2 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Wildland Urban Interface (WUI) Zone'],
      },
    ],
  },
  'property-5': {
    livingAreaSquareFootage: 1580,
    lotSquareFeet: 9506,
    maxLotCoverage: 3802.4,
    garageSF: 437,
    deckSF: 0,
    price: 1866600,
    bedroom: 4,
    bathroom: 2,
    livingUnits: 1,
    streetAddress: '78 Granada Dr.',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.9147416,
    lng: -122.5052764,
    zoningCode: 'R-1 - Medium Density (Greater then 7500 sq.ft.)',
    constructionArea: 'areaOne',
    featuredImage: 'https://p.rdcpix.com/v01/l0b1fa045-m0xd-w1020_h770_q80.jpg',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Chad Gerber' },
      { title: 'APN', data: '026-134-09' },
      { title: 'Construction Year', data: 1957 },
      { title: 'Max Floor Area Ratio', data: 3802.4 },
      { title: 'Max Lot Coverage', data: 3802.4 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 437 },
      { title: 'Deck/Patio Square Footage', data: 0 },
      { title: 'Slope', data: 20.5 },
      { title: 'Slope Requirement', data: 'Yes' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 3802.4 },
      { title: 'Max FAR (Floor Area Ratio)', data: 3802.4 },
      { title: 'Potential Issues', alert: true, data: ['Slope requirement'] },
    ],
  },
  'property-6': {
    livingAreaSquareFootage: 921,
    lotSquareFeet: 8581.8,
    maxLotCoverage: 2145.45,
    garageSF: 0,
    deckSF: 120,
    price: 953100,
    bedroom: 1,
    bathroom: 1,
    livingUnits: 1,
    streetAddress: '155 Summit Drive',
    city: 'Corte Madera',
    state: 'CA',
    zip: '94925',
    lat: 37.92225,
    lng: -122.535,
    zoningCode: 'R-1-A Low Density Residential District',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/1fd839c0e03674465874a18417d27090-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Yaron Palfy' },
      { title: 'APN', data: '025-083-65' },
      { title: 'Construction Year', data: 1931 },
      { title: 'Max Floor Area Ratio', data: 3432.72 },
      { title: 'Max Lot Coverage', data: 2145.45 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 0 },
      { title: 'Deck/Patio Square Footage', data: 120 },
      { title: 'Slope', data: 51 },
      { title: 'Slope Requirement', data: 'Yes, On side yard' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2145.45 },
      { title: 'Max FAR (Floor Area Ratio)', data: 3432.72 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Wildland Urban Interface (WUI) Zone'],
      },
    ],
  },
  'property-7': {
    livingAreaSquareFootage: 978,
    lotSquareFeet: 7500,
    maxLotCoverage: 3000,
    garageSF: 0,
    deckSF: 40,
    price: 1659200,
    bedroom: 2,
    bathroom: 1,
    livingUnits: 1,
    streetAddress: '116 Monte Vista Ave',
    city: 'Larkspur',
    state: 'CA',
    zip: '94939',
    lat: 37.9319942,
    lng: -122.5340194,
    zoningCode: 'R-1',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/be8bcbb0938cfc3d7010c22820b2ef76-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'John Maxwell' },
      { title: 'APN', data: '021-171-09' },
      { title: 'Construction Year', data: 1941 },
      { title: 'Max Floor Area Ratio', data: 3000 },
      { title: 'Max Lot Coverage', data: 3000 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 0 },
      { title: 'Deck/Patio Square Footage', data: 40 },
      { title: 'Slope', data: 6 },
      { title: 'Slope Requirement', data: 'Yes, On side yard' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'No' },

      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 3000 },
      { title: 'Max FAR (Floor Area Ratio)', data: 3000 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Slope requirement on side yard, surveyor may be needded'],
      },
    ],
  },
  'property-8': {
    livingAreaSquareFootage: 2244,
    lotSquareFeet: 9800,
    maxLotCoverage: 3920,
    garageSF: 499,
    deckSF: 0,
    price: 2226500,
    bedroom: 4,
    bathroom: 2.5,
    livingUnits: 1,
    streetAddress: '40 Lark Ct',
    city: 'Larkspur',
    state: 'CA',
    zip: '94939',
    lat: 37.9273574,
    lng: -122.5328583,
    zoningCode: 'R-1',
    constructionArea: 'areaOne',
    featuredImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/suburban-house-royalty-free-image-1584972559.jpg?crop=1.00xw:0.752xh;0,0.120xh&resize=1200:*',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Barbara Freitas' },
      { title: 'APN', data: '021-142-24' },
      { title: 'Construction Year', data: 1967 },
      { title: 'Max Floor Area Ratio', data: 2548 },
      { title: 'Max Lot Coverage', data: 3920 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 499 },
      { title: 'Deck/Patio Square Footage', data: 0 },
      { title: 'Slope', data: 23.1 },
      { title: 'Slope Requirement', data: 'Yes' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

      { title: 'Natural State', data: 48.1 },
      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 3920 },
      { title: 'Max FAR (Floor Area Ratio)', data: 2548 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Wildland Urban Interface'],
      },
    ],
  },
  'property-9': {
    livingAreaSquareFootage: 1519,
    lotSquareFeet: 8250,
    maxLotCoverage: 3920,
    garageSF: 617,
    deckSF: 522,
    price: 1897400,
    bedroom: 3,
    bathroom: 2,
    livingUnits: 1,
    streetAddress: '60 Elm Ave',
    city: 'Larkspur',
    state: 'CA',
    zip: '94939',
    lat: 37.9314745,
    lng: -122.5294973,
    zoningCode: 'R-1',
    constructionArea: 'areaOne',
    featuredImage:
      'https://photos.zillowstatic.com/fp/8eee900b27e9528dfe5d62ecfda4bf9b-uncropped_scaled_within_1536_1152.webp',
    propertyCharacteristics: [
      { title: 'Owner', data: 'Ross Murry' },
      { title: 'APN', data: '021-223-29' },
      { title: 'Construction Year', data: 1932 },
      { title: 'Max Floor Area Ratio', data: 2310 },
      { title: 'Max Lot Coverage', data: 2145 },
      { title: 'Max Lot Coverage Percentage', data: 0.4 },
      { title: 'Garage Area Square Footage', data: 617 },
      { title: 'Deck/Patio Square Footage', data: 522 },
      { title: 'Slope', data: 21.6 },
      { title: 'Slope Requirement', data: 'Yes' },
      { title: 'Waterway Conservation', data: 'No' },
      { title: 'Wildland Urban Interface (WUI) Zone', data: 'Yes' },

      { title: 'Natural State', data: 46.6 },
      { title: 'Flood Plane', data: 'No' },
    ],
    propertyPotential: [
      { title: 'Max Lot coverage', data: 2145 },
      { title: 'Max FAR (Floor Area Ratio)', data: 2310 },
      {
        title: 'Potential Issues',
        alert: true,
        data: ['Wildland Urban Interface'],
      },
    ],
  },
};
