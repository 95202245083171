import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import { useLocation } from 'react-router-dom';

import BottomNavigationMenu from '../components/BottomNavigationMenu';

import TopMenu from '../components/TopMenu';
import PropertyComponent from '../components/PropertyComponent';
import {
  FeaturedProperties,
  PropertyData,
  ZoningInformation,
} from '../utils/defaultData';

import { Fragment } from 'react';
import MyGoogleMap from '../components/MyGoogleMap';
import EstimatedConstructionCosts from '../components/EstimatedConstructionCosts';
import PropertyCharacteristics from '../components/PropertyCharacteristics';
import ConstructionCalculator from '../components/ConstructionCalculator';
import LocalPlanning from '../components/LocalPlanning';
import Resources from '../components/Resources';
import PropertyPotential from '../components/PropertyPotential';

import {
  addPropertyToFavorite,
  removePropertyFromFavorite,
  isLoggedIn,
} from '../utils/auth';
function Property(props) {
  const { pathname } = useLocation();

  const { isAuthorized, userData } = props;
  const [isFeaturedProperty, setIsFeaturedProperty] = useState(
    pathname.indexOf('featured') > -1
  );
  const [propertyData, setPropertyData] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [dataSelection, setDataSelection] = useState('');
  const [zoningInformation, setZoningInformation] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [_userData, setUserData] = useState(isLoggedIn());
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  console.log(_userData);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event) => {
    window.scrollTo(0, 0);
    setDataSelection(event.target.value);
  };
  useEffect(() => {
    if (pathname.indexOf('featured') > -1) {
      const _propertyId = /[^/]*$/.exec(pathname)[0];
      setPropertyId(propertyId);
      const _propertyData = FeaturedProperties.find(
        (x) => x.id === _propertyId
      ).data;
      setPropertyData(_propertyData);
      setZoningInformation(ZoningInformation[_propertyData.zoningCode]);
    } else {
      const _propertyId = /[^/]*$/.exec(pathname)[0];
      const _propertyData = PropertyData[_propertyId];
      setPropertyData(_propertyData);
      setZoningInformation(ZoningInformation[_propertyData.zoningCode]);
    }
  }, []);
  function removeFavorite() {
    let newPropData = removePropertyFromFavorite(/[^/]*$/.exec(pathname)[0]);
    setUserData(isLoggedIn());
    handleMenuClose();
  }
  function saveFavorite() {
    let newPropData = addPropertyToFavorite(
      /[^/]*$/.exec(pathname)[0],
      propertyData
    );
    setUserData(isLoggedIn());
    handleMenuClose();
  }
  function UnAuthorizedContent() {
    return (
      <Fragment>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          spacing={0}
          data-aos="fade"
          data-aos-duration="1000"
          className="paddingTopLarge"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="textCenter"
            key={propertyId}
          >
            <PropertyComponent
              id={propertyId}
              image={propertyData.featuredImage}
              address={
                propertyData.streetAddress +
                ', ' +
                propertyData.city +
                ', ' +
                propertyData.state
              }
              street={propertyData.streetAddress}
              location={propertyData.city + ', ' + propertyData.state}
              price={propertyData.price}
              gain={propertyData.maxGain}
              nonLiveableAreaSquareFootage={
                propertyData.garageSF + propertyData.deckSF
              }
              livingAreaSquareFootage={propertyData.livingAreaSquareFootage}
              maxLotCoverage={propertyData.maxLotCoverage}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          spacing={0}
          data-aos="fade"
          data-aos-duration="1000"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="textCenter paddingBottomSmall"
            key={propertyId}
          >
            <FormControl variant="outlined" className="fullWidth">
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="primary"
              >
                More Data
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataSelection}
                onChange={handleChange}
                label="Data Selections"
              >
                <MenuItem value="">
                  <em>Overview</em>
                </MenuItem>
                <MenuItem value={'propertyCharacteristics'}>
                  Property Characteristics
                </MenuItem>
                <MenuItem value={'propertyPotential'}>
                  Property Potential
                </MenuItem>
                <MenuItem value={'localPlanning'}>
                  Planning Development Standards
                </MenuItem>
                <MenuItem value={'resources'}>Zoning Codes/Ordinances</MenuItem>

                <MenuItem value={'estimatedConstructionCosts'}>
                  Estimated Construction Costs
                </MenuItem>
                <MenuItem value={'constructionCalculator'}>
                  Construction Calculator
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {(dataSelection === 'map' || dataSelection === '') && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            spacing={0}
            data-aos="fade"
            data-aos-duration="1000"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className="textCenter paddingBottomSmall"
              key={propertyId}
            >
              <div className="main-wrapper">
                <MyGoogleMap
                  address={
                    propertyData.streetAddress +
                    ', ' +
                    propertyData.city +
                    ', ' +
                    propertyData.state
                  }
                />
              </div>
            </Grid>
          </Grid>
        )}
        {dataSelection === 'estimatedConstructionCosts' && (
          <EstimatedConstructionCosts
            constructionArea={propertyData.constructionArea}
          />
        )}
        {dataSelection === 'propertyCharacteristics' && (
          <PropertyCharacteristics
            data={propertyData.propertyCharacteristics}
          />
        )}
        {dataSelection === 'propertyPotential' && (
          <PropertyPotential data={propertyData.propertyPotential} />
        )}
        {dataSelection === 'constructionCalculator' && (
          <ConstructionCalculator
            constructionArea={propertyData.constructionArea}
          />
        )}
        {dataSelection === 'localPlanning' && (
          <LocalPlanning
            zoningName={propertyData.zoningCode}
            data={zoningInformation.data}
          />
        )}
        {dataSelection === 'resources' && (
          <Resources
            zoningName={propertyData.zoningCode}
            resources={zoningInformation.resources}
          />
        )}
      </Fragment>
    );
  }
  function AuthorizedContent() {
    return (
      <Fragment>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          id="propertyShare"
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className="propertyShare"
        >
          <MenuItem onClick={handleMenuClose}>Assign to Client</MenuItem>
          <MenuItem onClick={handleMenuClose}>Send to Client</MenuItem>
          {JSON.stringify(
            _userData.savedProperties && _userData.savedProperties
          ).indexOf(/[^/]*$/.exec(pathname)[0]) > -1 && (
            <MenuItem onClick={removeFavorite}>Remove from Favorites</MenuItem>
          )}
          {JSON.stringify(
            _userData.savedProperties && _userData.savedProperties
          ).indexOf(/[^/]*$/.exec(pathname)[0]) === -1 && (
            <MenuItem onClick={saveFavorite}>Save to Favorites</MenuItem>
          )}
        </Menu>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={0}
          data-aos="fade"
          data-aos-duration="1000"
          className="paddingTopLarge"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="textCenter"
            key={propertyId}
          >
            <PropertyComponent
              id={propertyId}
              image={propertyData.featuredImage}
              address={
                propertyData.streetAddress +
                ', ' +
                propertyData.city +
                ', ' +
                propertyData.state
              }
              street={propertyData.streetAddress}
              location={propertyData.city + ', ' + propertyData.state}
              price={propertyData.price}
              gain={propertyData.maxGain}
              nonLiveableAreaSquareFootage={
                propertyData.garageSF + propertyData.deckSF
              }
              livingAreaSquareFootage={propertyData.livingAreaSquareFootage}
              maxLotCoverage={propertyData.maxLotCoverage}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="textCenter paddingBottomSmall"
            key={propertyId}
          >
            <FormControl variant="outlined" className="fullWidth">
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="primary"
              >
                More Data
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataSelection}
                onChange={handleChange}
                label="Data Selections"
              >
                <MenuItem value="">
                  <em>Overview</em>
                </MenuItem>
                <MenuItem value={'propertyCharacteristics'}>
                  Property Characteristics
                </MenuItem>
                <MenuItem value={'propertyPotential'}>
                  Property Potential
                </MenuItem>
                <MenuItem value={'localPlanning'}>
                  Planning Development Standards
                </MenuItem>
                <MenuItem value={'resources'}>Zoning Codes/Ordnances</MenuItem>

                <MenuItem value={'estimatedConstructionCosts'}>
                  Estimated Construction Costs
                </MenuItem>
                <MenuItem value={'constructionCalculator'}>
                  Construction Calculator
                </MenuItem>
              </Select>
            </FormControl>
            {(dataSelection === 'map' || dataSelection === '') && (
              <div className="main-wrapper">
                <MyGoogleMap
                  address={
                    propertyData.streetAddress +
                    ', ' +
                    propertyData.city +
                    ', ' +
                    propertyData.state
                  }
                />
              </div>
            )}
            {dataSelection === 'estimatedConstructionCosts' && (
              <EstimatedConstructionCosts
                constructionArea={propertyData.constructionArea}
              />
            )}
            {dataSelection === 'propertyCharacteristics' && (
              <PropertyCharacteristics
                data={propertyData.propertyCharacteristics}
              />
            )}
            {dataSelection === 'propertyPotential' && (
              <PropertyPotential data={propertyData.propertyPotential} />
            )}
            {dataSelection === 'constructionCalculator' && (
              <ConstructionCalculator
                constructionArea={propertyData.constructionArea}
              />
            )}
            {dataSelection === 'localPlanning' && (
              <LocalPlanning
                zoningName={propertyData.zoningCode}
                data={zoningInformation.data}
              />
            )}
            {dataSelection === 'resources' && (
              <Resources
                zoningName={propertyData.zoningCode}
                resources={zoningInformation.resources}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
  console.log(JSON.stringify(userData.savedProperties), propertyId);
  return (
    <>
      {_userData.savedProperties && (
        <TopMenu
          {...props}
          handleProfileMenuOpen={handleProfileMenuOpen}
          myProperty={
            JSON.stringify(_userData.savedProperties).indexOf(
              /[^/]*$/.exec(pathname)[0]
            ) > -1
          }
        />
      )}
      {!_userData.savedProperties && (
        <TopMenu {...props} handleProfileMenuOpen={handleProfileMenuOpen} />
      )}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        className="paddingTopMedium"
      >
        <Grid item xs={11}>
          {isAuthorized && propertyData && <AuthorizedContent />}
          {!isAuthorized && isFeaturedProperty && propertyData && (
            <UnAuthorizedContent />
          )}
        </Grid>
      </Grid>
      {isAuthorized && <BottomNavigationMenu />}
    </>
  );
}

export default Property;
