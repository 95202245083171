import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { formatNumber } from '../utils/formatter';

const useStyles = makeStyles({
  root: {
    maxWidth: '500px',
    maxHeight: '340px',
    margin: '0 auto',
    marginBottom: '1rem',
  },
  media: {
    height: 330,
  },
  cardPrice: {
    color: 'white',
    fontFamily: 'compassbold',
    fontWeight: 800,
    fontSize: '1.4rem',
  },
  cardGain: {
    color: 'green',
    fontFamily: 'compassbold',
    fontWeight: 800,
    fontSize: '1.4rem',
  },
  cardDetails: {
    color: 'white',
    fontFamily: 'compassmedium',
    fontSize: '0.7rem',
  },
  cardDetailsLeft: {
    color: 'white',
    fontFamily: 'compassmedium',
    float: 'left',
  },
  cardDetailsRight: {
    color: 'white',
    fontFamily: 'compassmedium',
    float: 'right',
  },
  borderBoth: {
    borderRight: '1px solid white',
    borderLeft: '1px solid white',
  },
});

function PropertyComponent(props) {
  const classes = useStyles();
  const totalBuilt =
    props.livingAreaSquareFootage + props.nonLiveableAreaSquareFootage;
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link
          className={props.link ? '' : 'inactive'}
          to={
            props.type && props.id !== null
              ? `/property/${props.type}/${props.id}`
              : `/property/${props.id}`
          }
          style={{ textDecoration: 'none' }}
        >
          <CardMedia
            className={classes.media}
            image={props.image}
            title={props.address}
          />
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={4}>
                <div>
                  <span className={classes.cardPrice}>
                    ${formatNumber(props.price)}
                  </span>
                </div>
                <div>
                  <span className={classes.cardDetails}>{props.street}</span>
                </div>
                <div>
                  <span className={classes.cardDetails}>{props.location}</span>
                </div>
              </Grid>
              <Grid item xs={8}>
                {/* <div>
                  <span className={classes.cardGain}>
                    +${formatNumber(props.gain)}
                  </span>
                </div> */}
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <div>
                      <span className={classes.cardDetails}>
                        {formatNumber(props.livingAreaSquareFootage)} SF
                      </span>
                    </div>
                    <div>
                      <span className={classes.cardDetails}>
                        Existing Livable
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.borderBoth}>
                    <div>
                      <span className={classes.cardDetails}>
                        {formatNumber(props.nonLiveableAreaSquareFootage)} SF
                      </span>
                    </div>
                    <div>
                      <span className={classes.cardDetails}>
                        Existing Non Livable
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <span className={classes.cardDetails}>
                        {formatNumber(
                          props.maxLotCoverage -
                            (props.livingAreaSquareFootage +
                              props.nonLiveableAreaSquareFootage)
                        )}{' '}
                        SF
                      </span>
                    </div>
                    <div>
                      <span className={classes.cardDetails}>
                        Potential Additional SF
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default PropertyComponent;
