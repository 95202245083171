import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';
import PageTracker from './components/GoogleAnalytics';
import HomePage from './pages/Home';
import SearchPage from './pages/Search';
import MyClientsPage from './pages/MyClients';
import SettingsPage from './pages/Settings';
import PropertyPage from './pages/Property';
import ClientPage from './pages/Client';

import routes from './constants/routes';
import ScrollIntoView from './utils/ScrollIntoView';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { isLoggedIn, addLoginData, logout } from './utils/auth';
import BottomNavigationMenu from './components/BottomNavigationMenu';
import MyProperties from './pages/MyProperties';
import ClientProperties from './pages/ClientProperties';

function Routes() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userData, setUserData] = useState({});

  function handleLogin() {
    addLoginData();
    let _userData = isLoggedIn();
    console.log(_userData);
    if (_userData) {
      setIsAuthorized(true);
      setUserData(_userData);
    }
  }
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // logout();
    let _userData = isLoggedIn();
    if (_userData) {
      setIsAuthorized(true);
      setUserData(_userData);
    }
  }, []);
  function refreshProps() {
    let _userData = isLoggedIn();
    if (_userData) {
      setIsAuthorized(true);
      setUserData(_userData);
    } else {
      setIsAuthorized(false);
      setUserData({});
    }
  }
  return (
    <>
      <PageTracker options={{}} />
      <ScrollIntoView>
        <Switch>
          <Route
            path={routes.SEARCH}
            render={(props) => (
              <SearchPage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />
          <Route
            path={routes.CLIENTS}
            render={(props) => (
              <MyClientsPage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />
          <Route
            path={routes.CLIENTPROPERTIES}
            render={(props) => (
              <ClientProperties
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />

          <Route
            path={routes.CLIENT}
            render={(props) => (
              <ClientPage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />
          <Route
            path={routes.MYPROPERTIES}
            render={(props) => (
              <MyProperties
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />

          <Route
            path={routes.SETTINGS}
            render={(props) => (
              <SettingsPage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />
          <Route
            path={routes.PROPERTY}
            render={(props) => (
              <PropertyPage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
              />
            )}
          />
          <Route
            path={routes.HOME}
            render={(props) => (
              <HomePage
                {...props}
                isAuthorized={isAuthorized}
                userData={userData}
                handleLogin={handleLogin}
                refreshProps={refreshProps}
              />
            )}
          />
        </Switch>
      </ScrollIntoView>
      <BottomNavigationMenu isAuthorized={isAuthorized} hide={false} />
    </>
  );
}

export default Routes;
