
import { DefaultUserData } from "../utils/defaultData";

export const isLoggedIn = function(){
    let userData = {
        name: JSON.parse(localStorage.getItem('ether-name')),
        email: JSON.parse(localStorage.getItem('ether-email')),
        city: JSON.parse(localStorage.getItem('ether-city')),
        state: JSON.parse(localStorage.getItem('ether-state')), 
        dreNumber: JSON.parse(localStorage.getItem('ether-dreNumber')), 
        profilePic: JSON.parse(localStorage.getItem('ether-profilePic')),
        savedProperties: JSON.parse(localStorage.getItem('ether-savedProperties')),
        clients: JSON.parse(localStorage.getItem('ether-clients'))
    }
    console.log(userData.name)
    if(userData.name !== null){
        return userData;
    }
    return false;
}
export const logout = function(){
    localStorage.clear();
}
export const addLoginData = function(data){
    if(!data){
        let dataKeys = DefaultUserData.map(x => Object.keys(x)[0]);

        for(let i = 0; i < DefaultUserData.length; i++){
            console.log(DefaultUserData[i][dataKeys[i]], dataKeys[i])
            localStorage.setItem('ether-' + dataKeys[i], JSON.stringify(DefaultUserData[i][dataKeys[i]]));
        }
        return true;
    } else {
        localStorage.setItem('ether-username', data);
        let userName = localStorage.getItem('ether-username');
        return userName;
    }
}

export const getMyProperties = function(){
    return JSON.parse(localStorage.getItem('ether-savedProperties'));
}


export const getClientInfo = function(clientId){
    let myClients = JSON.parse(localStorage.getItem('ether-clients'));

    return myClients.find(x => x.id === clientId)
}

export const addPropertyToFavorite = function(propertyId, data){
    let properties = JSON.parse(localStorage.getItem('ether-savedProperties'));
    let newProperty = {};
    newProperty.id = propertyId;
    newProperty.data = data;
    properties.push(newProperty);
    localStorage.setItem('ether-savedProperties', JSON.stringify(properties));
    return JSON.parse(localStorage.getItem('ether-savedProperties'));
}
export const removePropertyFromFavorite = function(propertyId){
    let properties = JSON.parse(localStorage.getItem('ether-savedProperties'));
    const toDelete = new Set([propertyId]);
    const newArray = properties.filter(obj => !toDelete.has(obj.id));
    localStorage.setItem('ether-savedProperties', JSON.stringify(newArray));
    return JSON.parse(localStorage.getItem('ether-savedProperties'));
}

export const addPropertyToClient = function(property, clients){
    console.log("Adding to client: ", property, clients);
    let myClients = JSON.parse(localStorage.getItem('ether-clients'));
    console.log(myClients);

    for(var i = 0; i<myClients.length; i++){
        for(var j = 0; j<clients.length; j++){
            if(myClients[i].id === clients[j]){
                console.log("Match: ", myClients[i]);
                let newClientData = myClients[i];
                newClientData.properties.push(property);
                myClients[i] = newClientData
            }
        }
    }
    console.log("New: ", myClients)
    localStorage.setItem('ether-clients', JSON.stringify(myClients));
    return true;
}